@import "../../genericStyles/index.module.scss";

.navWrapper {
	padding: 16px 10em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: sticky;
    top: 0;
    box-shadow: 2px 2px 8px 2px rgba(0, 26, 32, 0.2);
    z-index: 1000;
	background-color: $pure-white;
	.logoWrapper {
		img {
			cursor: pointer;
			height: 48px;
		}
	}
	.burgerMenu {
		display: none;
		&:hover {
			.dropdownMenu {
				display: block;
			}
		}
		.dropdownMenu {
			position: absolute;
			top: 73px;
			right: 0px;
			background-color: white;
			border-radius: 3px;
			border-top-right-radius: 0px;
			padding: 16px 24px;
			a {
				& + a {
					margin-top: 24px;
				}
				white-space: nowrap;
				display: block;

				color: $orient;
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 24px;
				text-decoration: underline;
				&:hover{
					color: $organic-black;
				  }
				&:active{
				color: $jewel;
				}
			}
		}
		& > * {
			font-size: 36px;
			color: black;
		}
	}
	.navTitles {
		display: block;
		a {
			padding: 0 12px;
			color: $orient;
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 24px;
			text-decoration: underline;
			&:hover{
				color: $organic-black;
			}
			&:active{
			color: $jewel;
			}
			&:last-child {
				padding: 0px;
			}
		}
	}
}
.mainBanner {
	background-repeat: no-repeat;
	// background-image: linear-gradient(90deg, #96E095 0%, #167434 35%, #00667E 100%);
    background-image: url(../../assets/svgs/fl-gradient-desktop.svg);
	background-size: 100% calc(100% - 90px);
	background-position: 50% 0px;
	.userInfoWrappper {
		display: flex;
		justify-content: center;
		width: 100%;
		&:first-child {
			padding-top: 90px;
		}
		.sectionContainer {
			display: flex;
			justify-content: space-between;
			.profileImg {
				height: 360px;
				width: 360px;
				border-radius: 50%;
				margin-right: 80px;
				background-color: white;
				overflow: hidden;
				position: relative;
				img {
					transform: translateX(-50%);
					height: 100%;
					position: absolute;
					top: 0;
					left: 50%;
				}
			}
			.userInfo {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				color: white;
				.userName {
					padding: 12px;
					font-family: $font-exo;
					font-style: normal;
					font-weight: normal;
					font-size: 38px;
					line-height: 57px;
					text-align: center;
					word-break: break-word;
				}
				.divider {
					padding: 12px;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					.miniCircle {
						border: 2px solid #00667E;
						border-radius:50%;
						width: 5px;
						height: 5px;
					}
					.maxCircle {
						border: 2px solid #96E095;
						border-radius:50%;
						width: 16px;
						height: 16px;
					}
					.medCircle {
						border: 2px solid #167434;
						border-radius:50%;
						width: 8px;
						height: 8px;
					}
				}
				.birth_death_dates {
					padding: 12px;
					font-family: $font-bitter;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					text-align: center;
				}
			}
		}
	}
}
.venueInformation {
	display: flex;
	justify-content: center;
	width: 100%;
	.sectionContainer {
		display: flex;
		justify-content: space-between;
		.venueLogo {
			width: 360px;
			margin-top: 20px;
			margin-bottom: 20px;
			margin-right: 80px;
			img {
				width: 100%;
				height: auto;
			}
		}
		.eventTiminings {
			padding: 12px;
			.eventMainHeader {
				font-family: $font-exo;
				font-style: normal;
				font-weight: 500;
				font-size: 32px;
				line-height: 48px;
				color: $organic-black;
			}
			.eventList {
				.eventItem {
					display: flex;
					white-space: normal;
					.eventHeader {
						width: 120px;
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 700;
						font-size: 16px;
						line-height: 24px;
						color: $organic-black;
						white-space: normal;
						word-break: break-word;
					}
					.eventTime {
						margin-right: 8px;
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: $organic-black;
					}
				}
			}
		}
	}
}
.userInformation {
	height: 740px;
	width: 100%;
	background-color: $jungle-mist;
	display: flex;
	justify-content: center;
	.userName {
		height: 32px;
		background-color: $pure-white;
		border: 1px solid $organic-black;
		border-radius: 24px;
		word-break: break-word;
	}
	.accessStreamBtn {
		width: 140px;
		height: 32px;
		background-color: $orient;
		border-radius: 4px;
		font-family: $font-bitter;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		text-align: center;
		cursor: pointer;
	}
}
.postMessageSection {
	margin-top: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.sectionContainer {
	min-width: 760px;
}
.formWrappper {
	.innerWrappper {
		padding-top: 200px;
		padding-bottom: 200px;
		.formHeading {
			font-family: $font-exo;
			font-style: normal;
			font-weight: 500;
			font-size: 32px;
			line-height: 48px;
			color: $organic-black;
		}
		.formElements {
			margin-top: 24px;
			.inputWrapperRow {
				& + .inputWrapperRow {
					margin-top: 16px;
				}
				display: flex;
				.inputWrapper {
					flex: 1;
					.inputHeader {
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 700;
						font-size: 16px;
						line-height: 24px;
						color: $organic-black;
						display: block;
						margin-left: 8px;
					}
					.inputError {
						padding: 4px 16px;
						outline: none;
						width: calc(100% - 10px);
						border-radius: 16px;
						min-height: 32px;
						background-color: white;
						border: 1px solid $error;
					}
					.inputElement {
						padding: 4px 16px;
						outline: none;
						width: calc(100% - 10px);
						border-radius: 16px;
						min-height: 32px;
						background-color: white;
						border: 1px solid $organic-black;
						&:focus {
							border: 1px solid $jewel;
							box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
						}
					}
					.previewVideo {
						display: flex;
						.inputElementAfter {
							padding: 4px 16px;
							outline: none;
							width: 540px;
							border-radius: 16px;
							min-height: 32px;
							background-color: white;
							border: 1px solid $organic-black;
							margin-right: 20px;
							&:focus {
								border: 1px solid $jewel;
								box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
							}
						}
						.videoPreview {
							border-radius: 8px;
						}
					}
					textarea {
						resize: none;
					}
				}
			}
		}
		.submitWrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 48px;
			padding-bottom: 10px;
			.hintMsg {
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 24px;
				color: $organic-black;
				margin-right: 8px;
			}
			.postBY {
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;
			}
			.uploadBtn {
				background: $jungle-mist;
				border-radius: 4px;
				font-family: $font-bitter;
				font-style: normal;
				padding: 8px 16px;
				height: 32px;
				border: none;
				font-weight: 600;
				font-size: 16px;
				line-height: 16px;
				text-align: center;
				color: $pure-black;
				cursor: pointer;
			}
		}
	}
}
.formWrappper {
	.formHeading {
		font-family: $font-exo;
		font-style: normal;
		font-weight: 500;
		font-size: 32px;
		line-height: 48px;
		color: $organic-black;
	}
	.formElements {
		margin-top: 24px;
		.inputWrapperRow {
			& + .inputWrapperRow {
				margin-top: 16px;
			}
			display: flex;
			.inputWrapper {
				flex: 1;
				.inputHeader {
					font-family: $font-bitter;
					font-style: normal;
					font-weight: 700;
					font-size: 16px;
					line-height: 24px;
					color: $organic-black;
					display: block;
					margin-left: 8px;
				}
				.emailError {
					padding: 4px 16px;
					outline: none;
					width: calc(100% - 10px);
					border-radius: 16px;
					min-height: 32px;
					background-color: white;
					border: 1px solid $error;
				}
				.inputElement {
					padding: 4px 16px;
					outline: none;
					width: calc(100% - 10px);
					border-radius: 16px;
					min-height: 32px;
					background-color: white;
					border: 1px solid $organic-black;
					&:focus {
						border: 1px solid $jewel;
						box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
					}
				}
				.previewVideo {
					display: flex;
					.inputElementAfter {
						padding: 4px 16px;
						outline: none;
						width: 540px;
						border-radius: 16px;
						min-height: 32px;
						background-color: white;
						border: 1px solid $organic-black;
						margin-right: 20px;
						&:focus {
							border: 1px solid $jewel;
							box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
						}
					}
					.videoPreview {
						border-radius: 8px;
					}
				}
				textarea {
					resize: none;
				}
			}
		}
	}
	.submitWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 16px;
		padding-bottom: 10px;
		.hintMsg {
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 24px;
			color: $organic-black;
			margin-right: 8px;
		}
		.postBY {
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
		}
		.uploadBtn {
			background: $jungle-mist;
			border-radius: 4px;
			font-family: $font-bitter;
			font-style: normal;
			padding: 8px 16px;
			height: 32px;
			border: none;
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: $pure-black;
			cursor: pointer;
		}
	}
}
.condolanceSection {
	margin-top: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	.sectionHeaderWrapper {
		padding: 0 8px;
		box-shadow: 0px -1px 0px $jungle-mist;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.sectionHeader {
			font-family: $font-exo;
			font-style: normal;
			font-weight: 500;
			font-size: 32px;
			line-height: 48px;
		}
		.sectionDescription {
			margin-top: 8px;
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 24px;
		}
		.expandIcon {
			color: $orient;
			font-size: 36px;
			cursor: pointer;
		}
	}
}
.errorText{
    position: absolute;
    color: $error;
	font-family: $font-bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
}
.postsSection {
	margin-top: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	.sectionContainer {
		max-width: 760px;
		.postMessageWithVideo{
			padding-bottom: 60px;
			justify-content: space-between;
			.reactPlayer{
				width: 200px;
				float: right;
				video{
					border-radius: 4px;
				}
			}
			.postMessageWarraper {
				width: 540px;
				& + .postMessageWarraper {
					margin-top: 8px;
				}
				.message {
					padding: 16px 24px;
					border-radius: 16px;
					background: rgba(196, 213, 217, 0.3);
					font-family: $font-bitter;
					font-style: normal;
					font-weight: 300;
					font-size: 16px;
					line-height: 24px;
					color: $organic-black;
					word-break: break-word;
				}
				.postBY {
					font-family: $font-bitter;
					font-style: normal;
					font-weight: 700;
					font-size: 16px;
					line-height: 24px;
				}
				.messageInfo_action {
					display: flex;
					padding: 8px 0px;
					align-items: center;
					justify-content: space-between;
					.message_info {
						display: flex;
						.postTimestamp {
							margin-left: 8px;
							font-family: $font-bitter;
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 24px;
						}
					}
					.replyBtn {
						border: none;
						padding: 8px 16px;
						background: $jungle-mist;
						border-radius: 4px;
						cursor: pointer;
						color: $organic-black;
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 16px;
						text-align: center;
					}
				}
			}
		}
		.postMessageWarraper {
			& + .postMessageWarraper {
				margin-top: 8px;
			}
			.message {
				padding: 16px 24px;
				border-radius: 16px;
				background: rgba(196, 213, 217, 0.3);
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 24px;
				color: $organic-black;
				word-break: break-word;
			}
			.postBY {
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;
			}
			.messageInfo_action {
				display: flex;
				padding: 8px 0px;
				align-items: center;
				justify-content: space-between;
				.message_info {
					display: flex;
					.postTimestamp {
						margin-left: 8px;
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
					}
				}
				.replyBtn {
					border: none;
					padding: 8px 16px;
					background: $jungle-mist;
					border-radius: 4px;
					cursor: pointer;
					color: $organic-black;
					font-family: $font-bitter;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 16px;
					text-align: center;
				}
			}
		}
		.postReplyWrraper {
			margin-left: 32px;
			& + .postReplyWrraper {
				margin-top: 8px;
			}
			.message {
				padding: 16px 24px;
				border-radius: 16px;
				background: rgba(196, 213, 217, 0.3);
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 24px;
				color: $organic-black;
				word-break: break-word;
			}
			.postBY {
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;
			}
			.messageInfo_action {
				display: flex;
				padding: 8px 0px;
				align-items: center;
				justify-content: space-between;
				.message_info {
					display: flex;
					.postTimestamp {
						margin-left: 8px;
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
					}
				}
				.replyBtn {
					border: none;
					padding: 8px 16px;
					background: $jungle-mist;
					border-radius: 4px;
					cursor: pointer;
				}
			}
		}
	}
}
.userForm {
	height: 100vh;
	background-color: $jungle-mist;
  .userContainer{
    display: flex;
    justify-content: center;
    .sectionContainer {
      .formWrappper {
        .innerWrappper {
          padding-top: 200px;
          padding-bottom: 200px;
          .formHeading {
            font-family: $font-exo;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: $organic-black;
          }
          .formElements {
            margin-top: 24px;
            .inputWrapperRow {
              & + .inputWrapperRow {
                margin-top: 16px;
              }
              display: flex;
              .inputWrapper {
                flex: 1;
                .inputHeader {
                  font-family: $font-bitter;
                  font-style: normal;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  color: $organic-black;
                  display: block;
                  margin-left: 8px;
                }
                .inputError {
                  padding: 4px 16px;
                  outline: none;
                  width: calc(100% - 10px);
                  border-radius: 16px;
                  min-height: 32px;
                  background-color: white;
                  border: 1px solid $error;
                }
                .inputElement {
                  padding: 4px 16px;
                  outline: none;
                  width: calc(100% - 10px);
                  border-radius: 16px;
                  min-height: 32px;
                  background-color: white;
                  border: 1px solid $organic-black;
                  &:focus {
                    border: 1px solid $jewel;
                    box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
                  }
                }
              }
            }
          }
        }
        .submitWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 48px;
            padding-bottom: 10px;
            .hintMsg {
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: $organic-black;
                margin-right: 8px;
            }
        }
      }
    }
  }
	.dropdownBtn {
		float: right;
		position: absolute;
		right: 0px;
		z-index: 10;
		.dropdownHeader {
			background-color: $pure-black;
			.burgerMenuTitle {
				// float: right;
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 24px;
				color: $pure-white;
				margin-left: 8px;
				margin-right: 300px;
			}
			.dropdownIcon {
				color: $pure-white;
				margin-right: 8px;
				cursor: pointer;
				float: right;
			}
		}
		.menu {
			max-height: 608px;
			overflow-y: scroll;
			.dropdownMenuItems {
				box-shadow: 0px 1px 0px black;
				border-top: 1px solid $pure-white;
				cursor: pointer;
				background-color: $pure-black;
				.otherClips {
					.streamName {
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: $pure-white;
						padding: 16px;
					}
					.videoFrame {
						float: right;
						padding-top: 30px;
					}
					.endTime {
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: $pure-white;
						padding: 0px 16px 16px;
					}
				}
				.streamNotStartedDropdownItem {
					.streamName {
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						color: $pure-white;
						padding: 8px;
					}
					.endTime {
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: $pure-white;
						padding: 0px 8px 8px;
					}
				}
			}
		}
	}
	.videoStreamInfo {
		// margin-left: 40px;
		height: 100vh;
		display: flex;
		align-items: center;
		.videoDetailInfo {
			padding-left: 100px;
			padding-bottom: 200px;
			.videoStreamDetailInfo {
				padding-top: 240px;
				padding-bottom: 24px;
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 24px;
				color: $organic-black;
			}
			.videoDeceasedNameInfo {
				font-family: $font-exo;
				font-style: normal;
				font-weight: 500;
				font-size: 32px;
				line-height: 48px;
				color: $organic-black;
				padding-bottom: 32px;
			}
		}
	}
	.reactPlayer {
		background-color: $pure-white;
	}
}
.errorText {
	position: absolute;
	color: $error;
	font-family: $font-bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
}
.actionBtn {
	font-family: $font-bitter;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	color: $organic-black;
	padding: 8px 16px 8px 16px;
	background: $jungle-mist;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	border: none;
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}
.withoutVideo {
	overflow: hidden;
	.noCamera {
		height: 500px;
		background-color: $pure-black;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		justify-content: center;
		.cameraBlock {
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			align-items: center;
			text-align: center;
			color: $pure-white;
			padding-bottom: 16px;
		}
		.blockHint {
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 24px;
			// display: flex;
			align-items: center;
			text-align: center;
			color: $pure-white;
		}
	}
	.exportBar {
		padding-top: 20px;
		padding-bottom: 20px;
		.fromDevice {
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: $orient;
			padding-right: 24px;
			cursor: pointer;
		}
		.actionBtn {
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: $organic-black;
			padding: 8px 16px 8px 16px;
			background: $jungle-mist;
			border-radius: 4px;
			display: inline-block;
			border: none;
			opacity: 0.5;
			pointer-events: none;
		}
	}
}
.errorText {
	position: absolute;
	color: $error;
	font-family: $font-bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
}
.exportBar {
	padding-top: 20px;
	padding-bottom: 20px;
	.fromDevice {
		font-family: $font-bitter;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		text-align: center;
		color: $orient;
		padding-right: 24px;
		cursor: pointer;
	}
	.actionBtn {
		font-family: $font-bitter;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 16px;
		text-align: center;
		color: $organic-black;
		padding: 8px 16px 8px 16px;
		background: $jungle-mist;
		border-radius: 4px;
		cursor: pointer;
		display: inline-block;
		border: none;
		&.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}
@media screen and (max-width: 1024px) {
	.navWrapper {
		padding: 16px 60px;
		.burgerMenu {
			display: block;
		}
		.logoWrapper {
			img {
				height: auto;
				width: 180px;
			}
		}
		.navTitles {
			display: none;
		}
	}
}
@media screen and (max-width: 768px) {
	.mainBanner{
		background-image: url(../../assets/svgs/fl-gradient-tablet.svg);
	}
	.formWrappper {
		.formElements {
			.inputWrapperRow {
				.inputWrapper {
					flex: initial;
					width: 100%;
					.previewVideo {
						display: flex;
						.inputElementAfter{
							width: 1800px;
						}
						.videoPreview{
							width: 100%;
							height: 100%;
						}
					}
				}
			}
		}
	}
	.withoutVideo {
		.noCamera {
			height: 500px;
		}
	}
	.sectionContainer {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}
	.userForm{
		display: flex;
		align-items: flex-start;
		padding-left: 40px;
		.dropdownBtn {
			.dropdownHeader {
				.burgerMenuTitle {
					margin-right: 200px;
				}
			}
		}
	}
	.navWrapper {
		padding: 16px 80px;
		.burgerMenu {
			display: block;
		}
		.logoWrapper {
			img {
				height: auto;
				width: 180px;
			}
		}
		.navTitles {
			display: none;
		}
	}
	.mainBanner {
		background-size: 100% calc(100% - 50px);
		.userInfoWrappper {
			.sectionContainer {
				flex-direction: column-reverse;
				justify-content: center;
				align-items: center;
				.profileImg {
					width: 255px;
					height: 255px;
					margin-right: 0px;
					margin-top: 80px;
				}
			}
		}
	}
	.venueInformation {
		.sectionContainer {
			flex-direction: row-reverse;
			align-items: center;
			justify-content: space-evenly;
			flex-wrap: wrap-reverse;
			.venueLogo {
				margin-right: 0px;
				width: 200px;
			}
			.eventTiminings {
				padding: 0px;
				white-space: nowrap;
			}
		}
	}
	.formWrappper {
		.formElements {
			.inputWrapperRow {
				flex-wrap: wrap;
				.inputWrapper {
					&:not(:first-child),
					&:not(:last-child) {
						margin-right: 0px;
						margin-left: 0px;
					}
					.inputElement {
						min-width: 320px;
						&:focus {
							border: 1px solid $jewel;
							box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
						}
					}
				}
			}
		}
	}
	.postsSection {
		.sectionContainer {
			min-width: calc(100% - 20px);
			max-width: calc(100% - 20px);
		}
	}
}
@media screen and (max-width: 420px) {
	.userForm{
		display: flex;
		align-items: flex-start;
		padding-left: 0px;
		.videoStreamInfo{
			height: 100vh;
			display: flex;
			align-items: center;
			.videoDetailInfo{
				padding-left: 40px;
			}
		}
	}
	.mainBanner{
		background-image: url(../../assets/svgs/fl-gradient-mobile.svg);
	}
	.withoutVideo {
		.noCamera {
			height: 300px;
		}
	}
	.navWrapper {
		padding: 16px 20px;
	}
	.postsSection{
		.sectionContainer{
			.postMessageWithVideo{
				display: block;
				.reactPlayer{
					width: 100%;
					float: none;
				}
				.postMessageWarraper{
					width: 100%;
				}
			}
		}
	}
	.postMessageSection{
		.sectionContainer{
			.formWrappper{
				.submitWrapper {
					display: block;
					justify-content: space-between;
					align-items: center;
					padding-bottom: 10px;
					.hintMsg{
						padding-bottom: 12px;
					}
				}
			}
		}
	}
	.formWrappper {
		.formElements {
			.inputWrapperRow {
				.inputWrapper {
					flex: initial;
					width: 100%;
					.previewVideo {
						display: block;
						.inputElementAfter{
							width: 100%;
						}
						.videoPreview{
							width: 100%;
							height: 100%;
							padding-top: 24px;
						}
					}
					.inputElement {
						min-width: calc(100% - 10px);
						&:focus {
							border: 1px solid $jewel;
							box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
						}
					}
				}
			}
		}
	}
}

.streamNotStartedDropdownItem {
	color: white;
	padding: 10px;
}
@import "../../genericStyles/index.module.scss";
.pageHeader{
    font-family: $font-inter;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: $orient;
}
.mainBody{
    margin-left: 170px;
    margin-right: 170px;
    .availablePoints{
        display: flex;
        align-items: center;
        width: 100%;
        .currentPoints{
            flex: 1;
            padding: 16px 24px 24px;
            background: $pure-white;
            border: 1px solid $jungle-mist;
            box-shadow: 0px 0px 4px 1px rgba(0, 102, 126, 0.15);
            border-radius: 8px;
            width: 50%;
            margin-right: 16px;
            .currentPointsHint1{
                display: flex;
                align-items: center;
                .preHint{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    color: $orient;
                }
                .postHint{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 48px;
                    color: $organic-black;
                    margin-left: 8px;
                    animation: postHint 1s ease;
                    -webkit-animation: postHint 1s ease;
                    @keyframes postHint {
                        from{transform:scale(0)}
                        80%{transform:scale(1.2)}
                        to{transform:scale(1)}
                    }
                    @-webkit-keyframes postHint {
                        from{-webkit-transform:scale(0)}
                        80%{-webkit-transform:scale(1.2)}
                        to{-webkit-transform:scale(1)}
                    }
                }
            }
            .currentPointsHint2{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: $organic-black
            }
        }
        .claimablePoints{
            flex: 1;
            background: $pure-white;
            border: 1px solid $jungle-mist;
            box-shadow: 0px 0px 4px 1px rgba(0, 102, 126, 0.15);
            border-radius: 8px;
            width: 40%;
            padding: 16px 24px 24px;
            .acclaimedPoints{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 48px;
                color: $organic-black;
            }
            .creditIncr{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .acclaimedPointsHint{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 21px;
                    color: $organic-black;
                }
                .incrementBtns{
                    display: flex;
                    align-items: center;
                    .creditInput{
                        width: 38px;
                        height: 32px;
                        border: 1px solid $jungle-mist;
                        background-color: $pure-white;
                        font-family: 'Bitter';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 14px;
                        text-align: center;
                        color: $organic-black;
                        opacity: 0.5;
                    }
                }
            }
            .claimablePointsTooltip{
                .tooltip{
                    position: relative;
                    display: inline-block;
                    .tooltiptext{
                        visibility: hidden;
                        width: 300px;
                        background: $pure-white;
                        border: 1px solid $light-grey1;
                        box-shadow: 0px 0px 4px 1px rgba(0, 102, 126, 0.15);
                        border-radius: 4px;
                        position: absolute;
                        z-index: 1;
                        top: 100%;
                        left: 50%;
                        padding: 8px;
                        &:after{
                            content: "";
                            position: absolute;
                            bottom: 100%;
                            left: 50%;
                            margin-left: -150px;
                            background: #FFFFFF;
                            border-left:1px solid #CAD5E0;
                            border-right:1px solid #CAD5E0;
                        }
                    }
                    &:hover{
                        .tooltiptext{
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .creditPackages{
        margin-top: 24px;
        margin-bottom: 8;
        .creditHeader{
            font-family: $font-exo;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            color: $organic-black;
            margin-bottom: 4;
        }
        .creditHint{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $organic-black;
        }
    }
}
.creditPackageCards{
    display: flex;
    .eachCard{
        background: $pure-white;
        border: 1px solid $jungle-mist;
        box-shadow: 0px 0px 4px 1px rgba(0, 102, 126, 0.15);
        border-radius: 8px;
        padding: 16px 24px;
        width: 25%;
        margin-right: 16px;
        .creditName{
            display: flex;
            align-items: center;
            .creditNumber{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 33px;
                color: $jewel;
            }
            .creditNumberHint{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
                color: $jewel;
            }
        }
        .creditHint{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
            color: $organic-black;
        }
        .creditPrice{
            margin-top: 16px;
            margin-bottom: 24px;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $orient;
        }
        .discountPrice{
            width: fit-content;
            padding: 2px 4px;
            margin-top: 8px;
            border-radius: 2px;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: $organic-black;
        }
    }
}
@media screen and (max-width: 1024px) {
    .mainBody{
        margin-left: 36px;
        margin-right: 36px;
    }
}
@media screen and (max-width: 768px) {
    .mainBody{
        margin-left: 72px;
        margin-right: 72px;   
    }
    .creditPackageCards{
        display: block;
        .eachCard{
            width: 100%;
            margin-bottom: 16px;
            text-align: center;
            .creditName{
                justify-content: center;
            }
            .discountPrice{
                justify-content: center;
            }
        }
    }
}
@media screen and (max-width: 420px) {
    .mainBody{
        margin-left: 36px;
        margin-right: 36px;
    }
    .creditPackageCards{
        display: block;
        .eachCard{
            width: 100%;
            margin-bottom: 16px;
            text-align: center;
            .creditName{
                justify-content: center;
            }
            .discountPrice{
                justify-content: center;
            }
        }
    }
}
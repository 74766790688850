.headerCell {
  background-color: #C4D5D9 !important;
  text-transform: uppercase !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #00667E !important;
}

.pagination {
  padding: 50px;
  padding-top: 0;
  background-color: #C4D5D9;
  color: #2A7E92;
}

.pageMover {
  float: right;
  padding-top: 15px;
}

.pageDropdown {
  float: left;
  padding-top: 15px;
}

.table {
  margin-bottom: 0 !important;
  width: 100%;
  table-layout: fixed;
}

.reactPlayer video{
  margin-bottom: -30%;
}

.player video{
  margin-bottom: -10%;
}

.webcam{
  transform: "scaleX(-1)";
  margin-bottom : -30%
}

@media only screen and (max-width: 768px) {
  .reactPlayer video{
    margin-bottom: 0%;
  }
  
  .player video{
    margin-bottom: 0%;
  }
  .webcam{
    margin-bottom : 0%
  }
}
@media only screen and (max-width: 420px) {
  .reactPlayer video{
    margin-bottom: 0%;
  }
  
  .player video{
    margin-bottom: 0%;
  }

  .webcam{
    margin-bottom : 0%
  }
}
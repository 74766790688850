@import "../../genericStyles/index.module.scss";
.mainBody{
    margin-left: 349px;
    margin-right: 349px;
    margin-top: 80px;
    padding-bottom: 80px;
    .header{
        font-family: $font-exo;
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 57px;
        color: $orient;
        margin-bottom: 8px;
    }
    .headerHint{
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $organic-black;
        margin-bottom: 24px;
    }
    .subHeader{
        font-family: $font-exo;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        color: $jewel;
        margin-bottom: 4px;
    }
    .subSubHeader{
        font-family: $font-exo;
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 39px;
        color: $organic-black;
        margin-bottom: 4px;
    }
    .subHeaderHint{
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $organic-black;
        margin-bottom: 24px;
        .subHeaderHintPoints{
            display: list-item;
            margin-left: 21px;
        }
        .emailTo{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-decoration-line: underline;
            color: $orient;
            cursor: pointer;
        }
    }
}
@media screen and (max-width: 1248px){
    .mainBody{
        margin-left: 80px;
        margin-right: 80px;
    }
}
@media screen and (max-width: 768px){
    .mainBody{
        margin-left: 80px;
        margin-right: 80px;
    }
}
@media screen and (max-width: 428px){
    .mainBody{
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
}
@import '../genericStyles/index.module.scss';
.modalPopupWrapper{
    background-color: $pure-white;
    max-height: calc(100% - 80px);
    width: 100%;
    max-width: calc(100% - 200px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 8px;
    overflow: auto;
    outline: 0;
    .modalPopupHeaderWrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        .modalPopupTitle{
            font-family: $font-bitter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
        }
        .modalPopupCloseBtn{
            .modalPopupCloseIcon{
                font-size: 24px;
                color: $pure-black;
                cursor: pointer;
            }
        }
    }
    .modalPopupBody{
        position: relative;
        .modalBody{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $pure-black;
        }
        .cancelBtn{
            background: $jungle-mist;
            border-radius: 4px;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: $organic-black;
            text-transform: capitalize;
        }
        .commitBtn{
            background: $error;
            border-radius: 4px;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: $pure-white;
            text-transform: capitalize;
        }
    }

}
.modalPopupWrapper3{
    background-color: $pure-white;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    max-height: calc(100% - 80px);
    transform: translate(-50%,-50%);
    border-radius: 8px;
    .modalPopupHeaderWrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        .modalPopupTitle{
            font-family: $font-bitter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
        }
        .modalPopupCloseBtn{
            .modalPopupCloseIcon{
                font-size: 24px;
                color: $pure-black;
                cursor: pointer;
            }
        }
    }
    .modalPopupBody{
        position: absolute;
        .modalBody{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $pure-black;
        }
        .cancelBtn{
            background: $jungle-mist;
            border-radius: 4px;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: $organic-black;
            text-transform: capitalize;
        }
        .commitBtn{
            background: $error;
            border-radius: 4px;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: $pure-white;
            text-transform: capitalize;
        }
        video{
            margin-bottom: -30%;
        }
    }

}
.modalPopup2Wrapper{
    background-color: $pure-white;
    max-height: calc(100% - 80px);
    max-width: calc(100% - 80px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 8px;
    overflow: auto;
    outline: 0;
    .modalPopupHeaderWrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        .modalPopupTitle{
            font-family: $font-bitter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
        }
        .modalPopupCloseBtn{
            .modalPopupCloseIcon{
                font-size: 24px;
                color: $pure-black;
                cursor: pointer;
            }
        }
    }
    .modalPopupBody{
        position: relative;
        .btns{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 16px;
            .cancelBtn{
                background: $jungle-mist;
                border-radius: 4px;
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                color: $organic-black;
                text-transform: capitalize;
                border: none;
                padding: 8px 16px;
                cursor: pointer;
            }
            .commitBtn{
                background: $orient;
                border-radius: 4px;
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                color: $pure-white;
                text-transform: capitalize;
                border: none;
                padding: 8px 16px;
                cursor: pointer;
            }
        }
        .modalHint{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $organic-black;
        }
        .cardData{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 16px;
            background: $pure-white;
            border: 1px solid $jungle-mist;
            border-radius: 8px;
            margin-top: 16px;
            .cardBody{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 21px;
                color: $organic-black;
            }
        }
        .modalBody{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $pure-black;
        }
        .cancelBtn{
            background: $jungle-mist;
            border-radius: 4px;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: $organic-black;
            text-transform: capitalize;
        }
        .commitBtn{
            background: $error;
            border-radius: 4px;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: $pure-white;
            text-transform: capitalize;
        }
    }

}
@media screen and (max-width: 1024px) {
    .modalPopupWrapper{
        max-width: calc(100% - 40px);
    }
}
@media screen and (max-width: 768px) {
    .modalPopupWrapper{
        max-width: calc(100% - 40px);
    }
}
@media screen and (max-width: 420px) {
    .modalPopupWrapper{
        max-width: calc(100% - 40px);
    }
}
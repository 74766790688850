@import "../../genericStyles/index.module.scss";

.navWrapper {
	padding: 16px 10em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0;
	box-shadow: 2px 2px 8px 2px rgba(0, 26, 32, 0.2);
	z-index: 1000;
	background-color: $pure-white;
	.logoWrapper {
		img {
			cursor: pointer;
			height: 48px;
		}
	}
	.burgerMenu {
		display: none;
		&:hover {
			.dropdownMenu {
				display: block;
			}
		}
		.dropdownMenu {
			position: absolute;
			top: 73px;
			right: 0px;
			background-color: white;
			border-radius: 3px;
			border-top-right-radius: 0px;
			padding: 16px 24px;
			a {
				& + a {
					margin-top: 24px;
				}
				white-space: nowrap;
				display: block;

				color: $orient;
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 300;
				font-size: 16px;
				line-height: 24px;
				text-decoration: underline;
				&:hover{
					color: $organic-black;
				}
				&:active{
					color: $jewel;
				}
			}
		}
		& > * {
			font-size: 36px;
			color: black;
		}
	}
	.navTitles {
		display: block;
		a {
			padding: 0 12px;
			color: $orient;
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 300;
			font-size: 16px;
			line-height: 24px;
			text-decoration: underline;
			&:hover{
				color: $organic-black;
			}
			&:active{
				color: $jewel;
			}
			&:last-child {
				padding: 0px;
			}
		}
	}
}
.sectionWrapper {
	padding-left: 10em;
	padding-right: 10em;
}
.errorText{
    position: absolute;
    color: $error;
	font-family: $font-bitter;
	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
}
.formWrapper {
	margin-top: 30px;
	.deceasedView{
		border: 1px solid $jungle-mist;
		border-bottom: none;
		padding-top: 12px;
		max-width: 50%;
		border-radius: 6px;
		.imageView{
			display: flex;
			height: 80px;
			align-items: center;
			.img{
				padding-right: 12px;
				padding-bottom: 12px;
				padding-left: 12px;
				.imageData{
					height: 60px;
					width: 60px;
					border-radius: 50%;
				}
			}
			.deceasedName{
				font-family: $font-bitter;
				font-style: normal;
				font-weight: 300;
				font-size: 18px;
				line-height: 24px;
				color: $organic-black;
			}
		}
		.submitWrapper{
			.submitBtn{
				background-color: $orient;
				color: $pure-white;
				border: 1px solid $orient;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
				width: 100%;
				padding-top: 8px;
				padding-bottom: 8px;
				cursor: pointer;
				&.disabled{
					opacity: 0.5;
					pointer-events: none;
			
				}
			}
		}
	}
	.formTitlte {
		font-family: $font-exo;
		font-style: normal;
		font-weight: 500;
		font-size: 32px;
		line-height: 48px;
		color: $pure-black;
	}
	.formHint {
		font-family: $font-bitter;
		font-style: normal;
		font-weight: 300;
		font-size: 18px;
		line-height: 24px;
		color: $pure-black;
	}
	.elementRows {
		padding-left: 10em;
		.formElementsRow {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			padding-bottom: 24px;
			.elementWrapper {
				max-width: 100%;
				margin-top: 16px;
				.elementLabel {
					margin-left: 0;
					margin-bottom: 6px;
					font-family: $font-bitter;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: $organic-black;
					display: block;
					margin-left: 16px;
					margin-bottom: 2px;
				}
				:global {
					.MuiInput-underline {
						border: 1px solid $jewel;
						border-radius: 24px;
						padding: 0px 10px;
						&:before {
							content: none;
						}
						&:after {
							border-bottom: none;
						}
					}
				}
				.inputEle {
					&.size_sm {
						width: 175px;
						max-width: calc(100% - 10px);
					}
					&.size_m {
						width: 270px;
						max-width: calc(100% - 10px);
					}
					&.size_l {
						width: 365px;
						max-width: calc(100% - 10px);
					}
					&.size_lg {
						width: 550px;
						max-width: calc(100% - 10px);
					}
					:global {
						.MuiInput-underline {
							border: 1px solid $pure-black;
							border-radius: 24px;
							padding: 0px 10px;
						}
						.MuiOutlinedInput-root {
							border-radius: 24px;
							padding: 0px;
							.MuiOutlinedInput-input {
								height: 22px;
								padding: 4px 16px;
								line-height: 22px;
							}
							.MuiOutlinedInput-notchedOutline {
								border-color: $organic-black;
								&:focus {
									border-color: $jewel;
									box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
								}
							}
						}
					}
				}
				.inputEleEdit {
					&.size_l {
						width: 365px;
						max-width: calc(100% - 10px);
					}
					:global {
						.MuiInput-underline {
							border-radius: 24px;
							padding: 0px 10px;
						}
						.MuiOutlinedInput-root {
							border-radius: 24px;
							padding: 0px;
							background-color: rgba(196, 213, 217, 0.5);
							.MuiOutlinedInput-input {
								color: $organic-black;
								opacity: 0.5;
								height: 22px;
								padding: 4px 16px;
								line-height: 22px;
							}
							.MuiOutlinedInput-notchedOutline {
								border: 1px solid $greyer;
								&:focus {
									border: 1px solid $jewel;
									box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
								}
							}
						}
					}
				}
				.inputEleError {
					&.size_m {
						width: 270px;
						max-width: calc(100% - 10px);
					}
					&.size_l {
						width: 365px;
						max-width: calc(100% - 10px);
					}
					:global {
						.MuiInput-underline {
							border-radius: 24px;
							padding: 0px 10px;
						}
						.MuiOutlinedInput-root {
							border-radius: 24px;
							padding: 0px;
							.MuiOutlinedInput-input {
								height: 22px;
								padding: 4px 16px;
								line-height: 22px;
							}
							.MuiOutlinedInput-notchedOutline {
								border: 1px solid $error;
							}
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 1024px) {
	.navWrapper {
		padding: 16px 80px;
		.burgerMenu {
			display: block;
		}
		.logoWrapper {
			img {
				height: auto;
				width: 180px;
			}
		}
		.navTitles {
			display: none;
		}
	}
	.formWrapper{
		.deceasedView{
			max-width: 100%;
		}
	}
}
@media screen and (max-width: 768px) {
	.sectionContainer {
		min-width: calc(100% - 10px);
		max-width: calc(100% - 10px);
	}
	.navWrapper {
		padding: 16px 60px;
		.burgerMenu {
			display: block;
		}
		.logoWrapper {
			img {
				height: auto;
				width: 180px;
			}
		}
		.navTitles {
			display: none;
		}
	}
	.formWrapper{
		.deceasedView{
			max-width: 100%;
		}
	}
}
@media screen and (max-width: 420px) {
	.navWrapper {
		padding: 16px 20px;
	}
	.formWrapper{
		.deceasedView{
			max-width: 100%;
		}
		.elementRows {
			padding-left: 5em;
		}
	}
	.sectionWrapper {
		padding-left: 2em;
		padding-right: 2em;
	}
}
.thumbnail {
	width: 200px;
}
.thumbnailWrapper {
	padding: 20px;
	margin: 10px;
}
@import "../../genericStyles/index.module.scss";
.innerHeaderCell {
  background-color: #F1F1F1 !important;
}
.topHeaderData{
  font-family: $font-inter;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: #00667E;
}
.pageHeader {
  font-family: $font-inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #00667E;
  text-transform: uppercase;
}

.filterDropDownButton {
  float: right;
  // margin: 10px;
}
.streamData{
  font-family: $font-inter;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: $organic-black;
}
.headerCellData{
  font-family: $font-inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  color: #00667E;
}
.successLabel {
  text-align: left;
  border-radius: 50px !important;
  color: #46AA45 !important;
  background-color: #CDFFCD !important;
  margin-top: 3px !important;
}

.failLabel {
  text-align: left;
  border-radius: 50px !important;
  background-color: #FFE0E0 !important;
  color: #E04444 !important;
  margin-top: 3px !important;
}

.warningLabel {
  text-align: left;
  border-radius: 50px !important;
  background-color: #FFEC91 !important;
  color: #9B8820 !important;
  margin-top: 3px !important;  
}

.homeHeader{
  font-family: $font-bitter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $organic-black;
}
.clearFilter{
  padding-top: 16px;
}
.innerTableHeader {
  background-color: #F1F1F1;
  color: $light-blue !important;
  text-transform: uppercase;
}
.indendentedRow {
  word-break: break-word !important;
}
.MuiCheckbox-colorSecondary{
  .Mui-checked{
    &:hover{
      background-color: white;
    }
  }
}
.startStopBtn {
  font-size: 12px !important;
  margin-left: 10px !important;
}
.statusPill {
  margin-left: 5px !important;
}
.header{
  padding: 50px;
  .funeralHeader{
    display: flex;
    justify-content: space-between;
  }
}
.dateSearch{
  border: 1px solid $organic-black;
  background-color: $pure-white;
  color: $organic-black;
  border-radius: 24px;
  padding: 4px 16px;
  font-family: $font-bitter;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  &:focus{
    border: 1px solid $orient;
  }
}
.burgerMenu{ 
  &:hover{
    .dropdownMenu{
      display: block;
    }
  }
  .dropdownMenu{
    position:absolute;
    top: auto;
    background-color: $pure-white;
    border-radius: 3px;
    z-index: 10;
    border: 1px solid #c4c4c4;
    &:hover{
      border: 1px solid $jewel;
      border: 1px solid #c4c4c4;
      box-shadow: 0px 2px 8px rgba(0, 127, 0, 0.4); 
    }
    .eachLink{
      padding: 8px;
      &:hover{
        background-color: #C4D5D9;
      }
      // &:first-child{
      //   padding-top: 8px;
      // }
      // &:last-child{
      //   padding-bottom: 8px;
      // }
      a{
        white-space: nowrap;
        display: block;
        cursor: pointer;
        color: $orient;
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        &:hover{
          color: $organic-black;
        }
        &:active{
          color: $jewel;
        }
      }
      span{
        white-space: nowrap;
        display: block;
        cursor: pointer;
        color: $orient;
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        &:hover{
          color: $organic-black;
        }
        &:active{
          color: $jewel;
        }
      }
    }
  }
  &>*{
    font-size: 36px;
    color: $pure-black;
  }
}

.condolenseMessages {
    position: relative;
    display: block;
    .message {
      display: block;
      position: relative;
      page-break-inside: avoid;
      margin-top: 16px;
      border-bottom: 1px solid $jungle-mist;
      padding-bottom: 8px;
      font-size: 16px;
      line-height: 24px;
      font-family: $font-bitter;
      font-weight: 300;
      .message {
        display: block;
        margin-left: 24px;
        border-bottom: none;
        position: relative;
        page-break-inside: avoid;
      }
      .messageText {
        margin-bottom: 8px;
        position: relative;
        page-break-inside: avoid;
      }
      .messageAttribution {
        .name, .date, .time {
          margin-right: 8px;
        }
        .name {
          font-weight: 700;
        }
      }
    }
  }

  .exportBar{
    padding: 24px 24px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 2px 8px 2px rgba(0,26,32,0.2);
    position: sticky;
    .inputLabel{
      padding-right: 16px;
      font-family: $font-bitter;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 16px;
      color: $organic-black;
      padding-left: 8px;
    }
    .inputEleError{
      &.size_m{
          width: 270px;
          max-width: calc(100% - 10px);
      }
      &.size_l{
          width: 365px;
          max-width: calc(100% - 10px);
      }
      &.size_lg{
          width: 550px;
          max-width: calc(100% - 10px);
      }
      :global{
          .MuiInput-underline{
              border-radius: 24px;
              padding: 0px 10px;
          }
          .MuiOutlinedInput-root{
              border-radius: 24px;
              padding: 0px;
              .MuiOutlinedInput-input{
                  height: 22px;
                  padding: 4px 16px;
                  line-height: 22px;
              }
              .MuiOutlinedInput-notchedOutline{
                  border: 1px solid $error;
              }
          }
      }
  }
    .errorText{
      position: absolute;
      color: $error;
      font-family: $font-bitter;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
  }
  .inputHint{
    position: absolute;
    font-family: $font-bitter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0px;
  }
  .btn{
    float: right;
  }
  }
  .modalPopupBody{
    padding: 16px 24px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
  .noMessages{
    text-align: center;
    font-family: $font-exo;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
    padding: 100px;
    color: $organic-black;
    .successText{
      font-family: $font-bitter;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
      color: $pure-black;
    }
  }
  .attendeesList{
    .attendeesAttribution{
      display: block;
      position: relative;
      page-break-inside: avoid;
      margin-top: 16px;
      border-bottom: 1px solid $jungle-mist;
      padding-bottom: 8px;
      font-size: 16px;
      line-height: 24px;
      font-family: $font-bitter;
      font-weight: 300;
      .name{
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 144%;
      }
      .email{
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: $pure-black;
      }
    }
  }
  }
  .url{
    font-family: $font-inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    text-decoration-line: underline;
    color: $orient;
    cursor: pointer;
    &:hover{
      color: $organic-black;
    }
  }

  input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: $pure-white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid $orient;
    cursor: pointer;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: $pure-white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 4px solid $orient;
}

.greyButton {
  background-color: #c4c4c4;
  border-radius: 3px;
}
.orientButton {
  background-color: #00667E;
  border-radius: 3px;
}
.organicBlackButton {
  background-color: #001A20;
  border-radius: 3px;
}
.whiteButton {
  background-color: white;
}

.clickable {
  cursor: pointer;
  text-decoration-style: solid;
  text-transform: none !important;
}
@media screen and (max-width: 1024px) {
  .header{
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .header{
    padding: 10px;
  }
}

@import "../../genericStyles/index.module.scss";

.navWrapper{
    padding: 16px 10em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 40px;
    // position: relative;
    background-color: $pure-white;
    position: sticky;
    top: 0;
    box-shadow: 2px 2px 8px 2px rgba(0, 26, 32, 0.2);
    z-index: 1000;
    .logoWrapper{
      img{
        cursor: pointer;
        height: 48px;
      }
    }
    .burgerMenu{ 
      display: none;
      &:hover{
        .dropdownMenu{
          display: block;
        }
      }
      .dropdownMenu{
        position:absolute;
        top: 73px;
        right: 0px;
        background-color: $pure-white;
        border-radius: 3px;
        border-top-right-radius: 0px;
        padding: 16px 24px;
        a{
          &+a{
            margin-top: 24px;
          }
          white-space: nowrap;
          display: block;
          color: $orient;
          font-family: $font-bitter;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          text-decoration: underline;
          &:hover{
            color: $organic-black;
          }
          &:active{
            color: $jewel;
          }
        }
      }
      &>*{
        font-size: 36px;
        color: $pure-black;
      }
    }
    .navTitles{
        display: block;
        select {
          border: none;
          box-shadow: none;
          background-color: white;
          color: rgb(0, 102, 126);
          max-width: 220px;
          font-family: Bitter, serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding: 8px 16px;
          margin-left: 32px;
          option {
            font-family: Bitter, serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            padding: 8px 16px;  
            border: none;
          }
        }
        select:focus option:checked {
          border: none
        }
        select:checked{
          border: none;
        }
        .select-wrap {
          position: relative;
        }
        select:focus {
          border-radius: 0px;
          outline: none;
        }
        .select-wrap:focus-within select {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10
        }
        
        a{
            padding: 0 12px;
            color: $orient;
            font-family: $font-bitter;
            cursor: pointer;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-decoration: underline;
            &:hover{
              color: $organic-black;
            }
            &:active{
              color: $jewel;
            }
            &:last-child{
                padding: 0px;
            }
        }
    }
}
@media screen and (max-width: 1024px) {
	.navWrapper {
		padding: 16px 80px;
		.burgerMenu {
			display: flex;
      select{
        height: 32px;
        font-size: 16px;
        margin-left: 32px;
      }
		}
		.logoWrapper {
			img {
				height: auto;
				width: 180px;
			}
		}
		.navTitles {
			display: none;
		}
	}
}
@media screen and (max-width: 768px) {
  .navWrapper {
		padding: 16px 60px;
		.burgerMenu {
			display: flex;
      select{
        height: 32px;
        font-size: 16px;
        margin-left: 16px;
      }
		}
		.logoWrapper {
			img {
				height: auto;
				width: 180px;
			}
		}
		.navTitles {
			display: none;
		}
	}
}
@media screen and (max-width: 420px) {
  .navWrapper {
		padding: 16px 20px;
	}
}

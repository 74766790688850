@import "../genericStyles/index.module.scss";
  .cancelButton{
    padding: 8px 16px;
    height: 32px;
    background: $jungle-mist;
    border-radius: 4px;
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: $organic-black;
    border: none;
    cursor: pointer;
    &.disabled{
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .checkoutFormHeader{
    font-family: $font-exo;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: $organic-black;
}
  .checkBoxLabel{
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    color: $organic-black;
    padding-left: 4px;
}
  .checkIcon {
    appearance: none;
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background-color: $pure-white;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid $orient;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
  }
  .checkIcon:after{
      font-family: "Font Awesome 5 free";
      font-weight: 900;
      content: url("../assets/svgs/checkMark.svg");
      color: $pure-white;
  }
  .checkIcon:checked{
      background-color: $orient;
  }
  .successRes{
    text-align: center;
    .successHeader{
      font-family: $font-exo;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 48px;
      color: $organic-black;
    }
    .successIcon{
      color: $jewel;
    }
    .successHint{
      font-family: $font-bitter;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: $organic-black;
      margin-bottom: 16px
    }
    .orderButton {
      padding: 8px 16px;
      background: $orient;
      border-radius: 4px;
      height: 32px;
      font-family: $font-bitter;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: $pure-white;
      border: none;
      cursor: pointer;
      width: 100%;
      margin-bottom: 16px;
      &.disabled{
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  .CheckoutFormData {
    .addCard{
      display: flex;
      align-items: center;
      font-family: $font-bitter;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      color: $orient;
      margin-top: 24px;
      margin-bottom: 24px;
      justify-content: center;
      cursor: pointer;
    }
    .paymentHint{
      font-family: $font-bitter;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: $organic-black;
    }
    .availableCreditCards{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 16px;
      height: 48px;
      background: $pure-white;
      border-radius: 8px;
      margin-top: 16px;
      .eachCard{
        display: flex;
        align-items: center;
      }
      .editDelete{
        display: flex;
        align-items: center;
        .last4{
          font-family: $font-bitter;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
          color: $organic-black;
          margin-right: 24px;
        }
        .icons{
          .EditIcon{
            color: #00667E;
            margin-right: 16px;
            cursor: pointer;
          }
        } 
      }
    }
    .orderButton {
			padding: 8px 16px;
			background: $orient;
			border-radius: 4px;
      height: 32px;
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 16px;
			text-align: center;
			color: $pure-white;
      border: none;
			cursor: pointer;
      width: 100%;
      margin-bottom: 16px;
      &.disabled{
        opacity: 0.5;
        pointer-events: none;
    }
		}
    .expiryCvc{
      display: flex;
      .elementWrapper{
        max-width: 100%;
        margin-bottom: 16px;
        .inputBoxError{
            background: $pure-white;
            border: 1px solid $error;
            border-radius: 24px;
            width: 100%;
            height: 32px;
            padding: 4px 16px;
            &:focus {
							outline: none !important;
						}
        }
        .inputBox{
            background: $pure-white;
            border: 1px solid $organic-black;
            border-radius: 24px;
            width: 100%;
            height: 32px;
            padding: 4px 16px;
            &:focus {
							outline: none !important;
              border-color: 1px solid $jewel;
              box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
						}
        }
    }
    }
    .elementWrapper{
        max-width: 100%;
        margin-bottom: 16px;
        .inputBox{
            background: $pure-white;
            border: 1px solid $organic-black;
            border-radius: 24px;
            width: 100%;
            height: 32px;
            padding: 4px 16px;
            &:focus {
							outline: none !important;
              border-color: 1px solid $jewel;
              box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
						}
        }
        .inputBoxEdit{
            background: #FBFBFB;
            border: 1px solid #C4D5D9;
            border-radius: 24px;
            width: 100%;
            height: 32px;
            padding: 4px 16px;
            &:focus {
              outline: none !important;
            }
        }
        .inputBoxError{
          background: $pure-white;
            border: 1px solid $error;
            border-radius: 24px;
            width: 100%;
            height: 32px;
            padding: 4px 16px;
            &:focus {
							outline: none !important;
						}
        }
        .errorText{
          position: absolute;
          color: $error;
          font-family: $font-bitter;
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 21px;
        }
    }
  }
  @media screen and (max-width: 1024px) {
    .checkoutForm{
      .expiryCvc{
        display: block;
      }
    }
}
@media screen and (max-width: 768px) {
  .checkoutForm{
    .expiryCvc{
      display: block;
    }
  }
}
@media screen and (max-width: 420px) {
  .checkoutForm{
    .expiryCvc{
      display: block;
    }
  }
}
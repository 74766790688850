@import "../../genericStyles/index.module.scss";

.printable  {
    max-width: 692px;
    margin: 32px auto;
    background-color: white;
    display: block;
    position: relative;
    padding: 20px;
    .condolenceHeader {
      width: 100%;
      font-family: $font-exo;
      font-size: 38px;
      line-height: 57px;
      color: $organic-black;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $organic-black;
    }
    .deceasedDetails {
      width: 100%;
      display: flex;
      margin: 24px 0;
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
      .deceasedImage {
        margin-right: 16px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
      .deceasedInfo {
        display: flex;
        flex-direction: column;
        .deceasedName {
          font-size: 32px;
          line-height: 48px;
          font-family: $font-exo;
        }
        .deceasedDates {
          font-size: 18px;
          line-height: 24px;
          font-family: $font-bitter;
          font-weight: 300;
        }
      }
    }
    .condolenseMessages {
      position: relative;
      display: block;
      .message {
        display: block;
        position: relative;
        page-break-inside: avoid;
        margin-top: 16px;
        border-bottom: 1px solid $jungle-mist;
        padding-bottom: 8px;
        font-size: 16px;
        line-height: 24px;
        font-family: $font-bitter;
        font-weight: 300;
        .message {
          display: block;
          margin-left: 24px;
          border-bottom: none;
          position: relative;
          page-break-inside: avoid;
        }
        .messageText {
          margin-bottom: 8px;
          position: relative;
          page-break-inside: avoid;
          word-break: break-word;
        }
        .messageAttribution {
          .name, .date, .time {
            margin-right: 8px;
          }
          .name {
            font-weight: 700;
          }
        }
      }
    }
    div.divFooter {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
      font-family: $font-bitter;
      font-weight: 700;
    }
  }
  
  @media print {
    div.divFooter {
      position: fixed;
      bottom: 0;
    }
  }
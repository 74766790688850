@import "../../genericStyles/index.module.scss";

.topHeader{
    background-repeat: no-repeat;
    // background-image: url(../assets/svgs/create-event-header.svg);
    background-image: linear-gradient(135deg, #001100 50%, #106038 65%, #5CAA9A 100%);
    background-size: 100% auto;
    background-position: 50% 50%;
    padding: 32px 0;
    .pageTitle{
        font-family: $font-exo;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 57px;
        color: $pure-white;
    }
}
.sectionContianer{
    padding: 0 160px;
}
.successMsgWrapper{
    margin-top: 160px;
    padding: 10px 10px;
    .elementalRow{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .elementalCol{
            margin-top: 8px;
            .checkMark{
                color: $jewel;
                font-size: 65px;
            }
            .linkText{
                margin-right: 8px;
            }
        }
    }
    .sectionTitle{
        font-family: $font-exo;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
    }
    .sectionSubTitle{
        font-family: $font-bitter;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;

    }
}
.linkText{
    font-family: $font-bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    color: $orient;
    text-align: center;
    text-decoration-line: underline;
    cursor: pointer;
}
.actionBtn{
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: $organic-black; 
    padding: 8px 16px 8px 16px;
    background: $jungle-mist;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    &.disabled{
        opacity: 0.5;
        pointer-events: none;

    }
}

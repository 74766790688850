.ui.button.btn:hover{
  background: #C4C4C4 !important;
  box-shadow: 0px 0px 4px 1px rgba(0, 102, 126, 0.15) !important;
}
.ui.button.btn:active{
  background: #E1EAEC !important;
  border: none !important;
}
.dropdown {
    position: relative;
    color: #333;
    cursor: default;
    width: 363px;
  }
  
  .dropdown .arrow {
    content: "";
    display: block;
    height: 0.5em;
    margin-top: 0.15rem;
    position: absolute;
    right: 10px;
    top: 8px;
    width: 0.5em;
    border-right: 2px solid #00667E;
    border-top: 2px solid #00667E;
    transform: rotate(135deg);
    cursor: pointer;
  }
  
  .dropdown .arrow.open {
    border-right: 2px solid #00667E;
    border-top: 2px solid #00667E;
    transform: rotate(-45deg);
    top: 8px;
    margin-top: 0.4em;
    cursor: pointer;
  }
  
  .dropdown .selectedValue {
    height: 32px;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid black;
    border-radius: 24px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    padding: 4px 16px;
    transition: all 200ms ease;
    width: 100%;
  }
  .dropdown .editValue {
    height: 32px;
    font-size: 1rem;
    border: 1px solid #C4D5D9;
    border-radius: 24px;
    box-sizing: border-box;
    pointer-events: none;
    outline: none;
    color: #001A20;
    padding: 4px 16px;
    transition: all 200ms ease;
    width: 100%;
    background-color: rgba(196, 213, 217, 0.5);
  }
  .dropdown .selectedValue:focus{
    border: 1px solid #167434;
    box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
  }
  
  .dropdown .options {
    display: none;
    background-color: #fff;
    border: 1px solid #C4D5D9;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
  }

  .dropdown .options1 {
    display: none;
    background-color: #fff;
    border: 1px solid #C4D5D9;
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: -1px;
    padding-bottom: 20px;
    position: absolute;
    overflow-y: auto;
    top: 100%;
    width: 100%;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
  }

  .dropdown .options .sub-option{
    padding-left: 20px;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;
    color: #001A20;
  }
  .dropdown .options1 .sub-option{
    padding-left: 20px;
    cursor: pointer;
    padding-bottom: 10px;
    padding-top: 10px;
    color: #001A20;
  }
  
  .dropdown .options.open {
    display: block;
  }
  .dropdown .options1.open {
    display: block;
  }
  
  .dropdown .option {
    box-sizing: border-box;
    background: #FFFFFF;
    color: #001A20;
    cursor: pointer;
    border-radius: 8px;
    display: block;
    padding: 8px 10px;
    font-family: Bitter,serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }

  .dropdown .option .first-option{
    /* pointer-events: none; */
    color: #667679;
  }
  
  .dropdown .option.selected,
  .dropdown .option:hover {
    color: #001A20;
  }
  
  div[disabled]
{
  /* pointer-events: none; */
  cursor: not-allowed;
}

input:disabled {
  color: #001A20;
  /* pointer-events: none; */
}
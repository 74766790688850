@import "../../genericStyles/index.module.scss";
.body{
    padding: 40px 160px;
    .fromDevice {
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        color: $orient;
        padding-right: 24px;
        cursor: pointer;
    }
    .deceasedDetails{
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border: 1px solid rgba(0, 102, 126, 0.15);
        box-shadow: 1px 1px rgba(0, 102, 126, 0.15);
        border-radius: 4px;
        .profileImgWithDetails{
            display: flex;
            align-items: center;
            .profileImg{
                width: 166px;
                height: 166px;
                border-radius: 50%;
                margin-right: 16px;
            }
            .deceasedPersonalDetails{
                .firstLastName{
                    font-family: $font-exo;
                    font-weight: 500;
                    font-Size: 26px;
                    line-height: 39px;
                    color: $orient;
                }
                .dobDod{
                    font-family: $font-bitter;
                    font-weight: 300;
                    font-Size: 18px;
                    line-height: 24px;
                    color: $organic-black;
                }
            }
        }
        .streamDetails{
            margin-right: 48px;
            .streamCount{
                font-family: $font-exo;
                font-weight: 500;
                font-Size: 26px;
                line-height: 39px;
                color: $orient;
            }
            .streams{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 120px;
                .eventItem {
					display: flex;
					white-space: normal;
					.eventHeader {
						width: 120px;
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 700;
						font-size: 16px;
						line-height: 24px;
						color: $organic-black;
						white-space: normal;
						word-break: break-word;
					}
					.eventTime {
						margin-right: 8px;
						font-family: $font-bitter;
						font-style: normal;
						font-weight: 300;
						font-size: 16px;
						line-height: 24px;
						color: $organic-black;
					}
				}
            }
        }
    }
    .faq{
        padding: 16px;
        margin-top: 32px;
        border: 1px solid rgba(0, 102, 126, 0.15);
        box-shadow: 1px 1px rgba(0, 102, 126, 0.15);
        border-radius: 4px;
        .faqHint{
            display: flex;
            align-items: center;
            font-family: $font-exo;
            font-weight: 500;
            font-Size: 22px;
            line-height: 33px;
            color: $jewel;
            .faqHintIcon{
                color: $jewel;
                margin-right: 8px;
            }
        }
        .faqStatements{
            .faqStatementSubtext{
                font-family: $font-bitter;
                font-weight: 800;
                font-Size: 16px;
                line-height: 24px;
                color: $organic-black;
                margin-right: 4px;
            }
            .faqStatementSubtext2{
                font-family: $font-bitter;
                font-weight: 300;
                font-Size: 16px;
                line-height: 24px;
                color: $organic-black;
            }
        }
    }
    .streamsValidity{
        flex: 1;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 32px;
        gap: 16px;
        .eachStreamData{
            padding: 16px;
            border: 1px solid rgba(0, 102, 126, 0.15);
            box-shadow: 1px 1px rgba(0, 102, 126, 0.15);
            border-radius: 4px;
            .eachStreamDetails{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                .eachStreamName{
                    font-family: $font-exo;
                    font-weight: 500;
                    font-Size: 22px;
                    line-height: 33px;
                    color: $orient;
                }
                .streamNotStartedState{
                    font-family: $font-inter;
                    font-weight: 500;
                    font-Size: 14px;
                    line-height: 16px;
                    color: $organic-black;
                    padding: 2px 8px;
                    border-radius: 30px;
                    border: 1px solid $light-grey1;
                    background-color: $light-grey1;
                    display: flex;
                    align-items: center;
                }
                .streamStartedState{
                    font-family: $font-inter;
                    font-weight: 500;
                    font-Size: 14px;
                    line-height: 16px;
                    color: $jewel;
                    padding: 2px 8px;
                    border-radius: 30px;
                    border: 1px solid rgba(150, 224, 149, 1);
                    background-color: rgba(150, 224, 149, 1);
                    display: flex;
                    align-items: center;
                }
                .streamEndedState{
                    font-family: $font-inter;
                    font-weight: 500;
                    font-Size: 14px;
                    line-height: 16px;
                    color: $error;
                    padding: 2px 8px;
                    border-radius: 30px;
                    border: 1px solid rgba(255, 222, 222, 1);
                    background-color: rgba(255, 222, 222, 1);
                    display: flex;
                    align-items: center;
                }
                .streamPausedState{
                    font-family: $font-inter;
                    font-weight: 500;
                    font-Size: 14px;
                    line-height: 16px;
                    color: $organic-black;
                    padding: 2px 8px;
                    border-radius: 30px;
                    border: 1px solid rgba(255, 222, 222, 1);
                    background-color: rgba(255, 222, 222, 1);
                    display: flex;
                    align-items: center;
                }
                .streamStartStopDetails{
                    .streamData{
                        margin-bottom: 8px;
                    }
                }
                .streamDataSubtext{
                    font-family: $font-bitter;
                    font-weight: 800;
                    font-Size: 16px;
                    line-height: 24px;
                    color: $organic-black;
                    margin-right: 4px;
                }
                .streamDataSubtext2{
                    font-family: $font-bitter;
                    font-weight: 300;
                    font-Size: 16px;
                    line-height: 24px;
                    color: $organic-black;
                }
                .streamNotStarted{
                    height: 200px;
                    width: 300px;
                    border-radius: 4px;
                    background-color: $light-grey2;
                    .cameraPreviewHints{
                        font-family: $font-exo;
                        font-weight: 500;
                        font-Size: 16px;
                        line-height: 24px;
                        color: $pure-white;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .eachStreamBtns{
                margin-top: 16px;
            }
        }
    }
}
@import "../../genericStyles/index.module.scss";
@import "../../genericStyles/table.module.scss";

.bodyWrapper{
    padding: 50px;
}
.navPath{
    font-family: $font-inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: $orient;
    text-transform: uppercase;
    .moveTo{
        font-family: $font-inter;
        text-decoration: underline;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: $orient;
        text-transform: uppercase;
    }
}
.topHeaderData{
    font-family: $font-inter;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: $organic-black;
}
.topHeaderDataActiveStatus{
    font-family: $font-inter;
    font-weight: 500;
    font-Size: 14px;
    line-height: 16px;
    color: $jewel;
    padding: 2px 8px;
    border-radius: 30px;
    border: 1px solid rgba(150, 224, 149, 1);
    background-color: rgba(150, 224, 149, 1);
    width: 60px;
}
.topHeaderDataInactiveStatus{
    font-family: $font-inter;
    font-weight: 500;
    font-Size: 14px;
    line-height: 16px;
    color: $error;
    padding: 2px 8px;
    border-radius: 30px;
    border: 1px solid rgba(255, 222, 222, 1);
    background-color: rgba(255, 222, 222, 1);
    width: 65px;
}
.pageTitle{
    font-family: $font-exo;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color: $organic-black;
    text-transform: capitalize;
}
.sectionWrapper{
    margin-top: 48px;
}
.tabsContainer{
    margin-top: 8px;
    :global{
        .MuiTabs-root{
            .MuiTab-root{
                &.MuiButtonBase-root{
                    &.Mui-selected{
                        color: $pure-black;
                    }
                    &:not(.Mui-selected):after{
                        content:"";
                        display: inline-block;
                        position: relative;
                        left: 0px;
                        width: 100%;
                        bottom: 0;
                        height: 2px;
                        position: absolute;
                        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                        background-color: #d9d9d9;
                    }
                }
            }
        }
    }
}
.emptyTestScreen{
    max-width: 100%;
    height: 400px;
    background-color: $light-grey1;
    margin: 24px 160px;
}
.checkCameraBtns{
    display: flex;
    align-items: center;
    justify-content: center;
}
.homeDetailsPanel{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .homeDetails{
        .homeDetailWrapper{
            display: inline-block;
            &+.homeDetailWrapper:before{
                content: " • "
            }
            .homeDetailTitle{
                font-family: $font-inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #667679;
            }
            .homeDetailValue{
                font-family: $font-inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: black
            }
        }
    }
    .homeActions{
        display: flex;
        align-items: center;
        >*{
           margin: 5px;
        }
    }
}
.usersPanel{
    .headerRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headerName{
            font-family: $font-inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
        }
        .filterWrapper{
            margin-left: 8px;
        }
    }
}
.elementWrapper{
    max-width: 100%;
    margin-top: 16px;
    .elementLabel{
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $organic-black;
        display: block;
        margin-left: 16px;
        margin-bottom: 2px;
    }
    .inputEleError{
        &.size_m{
            width: 270px;
            max-width: calc(100% - 10px);
        }
        &.size_l{
            width: 365px;
            max-width: calc(100% - 10px);
        }
        :global{
            .MuiInput-underline{
                border-radius: 24px;
                padding: 4px 16px;
            }
            .MuiOutlinedInput-root{
                border-radius: 24px;
                padding: 0px;
                .MuiOutlinedInput-input{
                    height: 22px;
                    padding: 4px 16px;
                    line-height: 22px;
                }
                .MuiOutlinedInput-notchedOutline{
                    border: 1px solid $error;
                }
            }
        }
    }
    .inputSet{
        margin-left: 16px;
    }
}
.errorText{
    position: absolute;
    color: $error;
    margin-left: 12px;
    margin-top: 4px;
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
}
.successText{
    position: absolute;
    color: $success;
    margin-top: 4px;
    margin-left: 12px;
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
}
@media screen and (max-width: 420px) {
    .homeDetailsPanel{
        .homeActions{
            display: block;
        }
    }
}
@import "../../genericStyles/index.module.scss";
.main{
    .landingPage{
        .landingHeader{
            background: linear-gradient(271.14deg, #00667E 0.98%, #001A20 99.53%);
            height: 520px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 160px;
            padding-right: 120px;
            img{
                height: 557px;
                width: 800px;    
                margin-top: 22px;        
            }
            .landingHeaderDesc{
                max-width: 394px;
                .landingHeaderDesc1{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 38px;
                    line-height: 57px;
                    color: $pure-white;
                }
                .landingHeaderDesc2{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 48px;
                    color: $pure-white;
                }
                .landingHeaderDesc3{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 24px;
                    color: $pure-white;
                    margin-bottom: 16px;
                }
            }
        }
        .landingInfo{
            padding: 80px 160px;
            display: flex;
            .landingInfoImage{
                img{
                    width: 308.5px;
                    height: 188.5px;
                }
            }
            .landingInfoDesc{
                .landingInfoDescHeader{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 48px;
                    color: $organic-black;
                }
                .landingInfoDescHeaderHint{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 24px;
                    color: $organic-black;
                }
                .landingInfoDescHeaderCaption{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                    color: $jewel;
                }
            }
            .landingInfoIcons{
                .landingIconText{
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    .iconHint{
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        color: $organic-black;
                        margin-left: 8px;
                    }
                }
            }
        }
        .landingDetails{
            padding: 40px 160px;
            background: $jungle-mist;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .landingDetailsLeft{
                max-width: 436px;
                .landingDetailsLeftHeader{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 48px;
                    color: $organic-black;
                }
                .landingDetailsLeftHint{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 24px;
                    color: $organic-black;
                }
            }
            .landingDetailsRight{
                .landingDetailsRightHeader{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 48px;
                    color: $orient;
                }
                .landingDetailsEachPoint{
                    display: flex;
                    align-items: center;
                    .landingDetailsSno{
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: $jewel;
                    }
                    .landingDetailsPoint{
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 24px;
                        color: $organic-black;
                        margin-left: 4px;
                    }
                }
            }
        }
        .landingAdvantages{
            padding: 40px 160px;
            display: flex;
            flex: 1;
            flex-direction: row;
            flex-wrap: wrap;
            .landingEachAdvantage{
                display: flex;
                flex: 1;
                flex-direction: column;
                padding: 24px;
                .landingEachAdvantageHeader{
                    display: flex;
                    align-items: center;
                    margin-bottom: 24px;
                    .landingEachAdvantageHeading{
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        color: $organic-black;
                    }
                }
                .landingEachAdvantagePoints{
                    .landingEachAdvantageEachPoint{
                        display: list-item;
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 21px;
                        color: $organic-black;
                        margin-left: 48px;
                    }
                }
            }
        }
        .requestDemo{
            padding: 40px 160px;
            background: $jungle-mist;
            .requestDemoHeader{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 48px;
                color: $pure-black;
            }
            .requestDemoHint{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: $pure-black;
                margin-bottom: 16px;
            }
            .requestDemoInputRow1{
                display: flex;
                flex: 1;
                flex-direction: row;
                flex-wrap: wrap;
                .requestDemoInputEle{
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    margin-right: 16px;
                    .elementLabel{
                        margin-left: 4px;
                    }
                    .inputBar{
                        background: $pure-white;
                        border: 1px solid $organic-black;
                        border-radius: 24px;
                        width: 277px;
                        height: 32px;
                        padding: 8px 16px;
                    }
                }
            }
        }
        .landingBundles{
            padding: 40px 160px;
            .landingBundlesHeader{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 48px;
                color: $organic-black;
            }
            .landingBundlesHint{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: $organic-black;
                margin-bottom: 36px;
            }
        }
        .landingBundlesEnd{
            margin-top: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 40px 160px;
            .image1{
                height: 317px;
                width: 167px;
            }
            .image2{
                height: 317px;
                width: 465px;
            }
            .landingBundlesEndHeaderPart{
                width: 420px;
                .landingBundlesEndHeader{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 34px;
                    color: $orient;
                    margin-bottom: 32px;
                }
                .landingBundlesEndPoints{
                    .landingBundlesEachPoint{
                        display: list-item;
                        margin-left: 40px;
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 24px;
                        color: #373D3F;
                    }
                }
            }
        }
    }
    .footer{
        background: $jungle-mist;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 160px;
        .footerLogo{
            display: flex;
            align-items: center;
            img{
                height: 48px;
            }
            .footerLogoWord1{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 200;
                font-size: 60px;
                line-height: 80px;
                color: $pure-black;
            }
            .footerLogoWord2{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 700;
                font-size: 60px;
                line-height: 80px;
                color: $pure-black;
            }
        }
        .footerLinks{
            .url{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                text-decoration-line: underline;
                color: $orient;
                cursor: pointer;
                &:first-child {
                    margin-right: 16px;             
                }
            }
        }
    }
}
@media screen and (max-width: 1024px){
    .main{
        .landingPage{
            .landingHeader{
                padding-left: 80px;
                padding-right: 0px;
            }
            .landingAdvantages{
                padding-left: 80px;
                padding-right: 80px;
                padding-top: 40px;
                padding-bottom: 40px;
                .landingEachAdvantage{
                    padding: 24px;
                }
            }
            .landingInfo{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                .landingInfoImage{
                    grid-area: 2 / 1 / 2 / 1;
                }
                .landingInfoDesc{
                    grid-area: 1 / 1 / 1 / 4;
                }
                .landingInfoIcons{
                    display: block;
                    grid-area: 2 / 3 / 3 / 3;
                }
            }
            .landingDetails{
                display: block;
                padding: 40px 160px;
                .landingDetailsLeft{
                    max-width: 100%;
                }
            }
            .requestDemo{
                padding: 40px 80px;
            }
            .landingBundles{
                padding: 40px 40px;
            }
            .landingBundlesEnd{
                .image1{
                    width: 147.88px;
                    height: 280.71px;
                }
                .image2{
                    width: 502px;
                    height: 310px;
                }
                display: block;
                padding: 40px 80px;
                .landingBundlesEndHeaderPart{
                    width: 100%;
                }
                img{
                    margin-top: 24px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
@media screen and (max-width: 888px){
    .main{
        .landingPage{
            .landingHeader{
                padding-left: 80px;
                padding-right: 0px;
                height: 440px;
                img{
                    height: 450px;
                    width: 434px;                
                }
            }
        }
    }
}
@media screen and (max-width: 768px){
    .main{
        .landingPage{
            .landingBundles{
                padding: 40px 24px;
            }
            .requestDemoInputRow1{
                display: block;
            }
            .landingHeader{
                padding-left: 80px;
                padding-right: 0px;
                height: 440px;
                img{
                    height: 450px;
                    width: 434px;                
                }
            }
            .landingInfo{
                display: grid;
                padding: 80px 80px;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 1fr 1fr;
                .landingInfoImage{
                    grid-area: 2 / 1 / 2 / 1;
                    display: flex;
                    align-items: center;
                }
                .landingInfoDesc{
                    grid-area: 1 / 1 / 1 / 4;
                }
                .landingInfoIcons{
                    grid-area: 3 / 1 / 3 / 1;
                    .landingIconText{
                        justify-content: center;
                        &:first-child{
                            margin-left: 40px;
                        }
                    }
                }
            }
            .landingDetails{
                padding: 80px 80px;
            }
            .landingBundlesEnd{
                .image1{
                    height: 317px;
                    width: 167px;
                }
                .image2{
                    width: 316.76px;
                    height: 193.63px;
                }
            }
            .requestDemo{
                padding: 40px 80px;
                .requestDemoInputRow1{
                    .requestDemoInputEle{
                        .inputBar{
                            width: 608px;
                        }
                    }
                }
            }
        }
        .footer{
            display: block;
        }
    }
}
@media screen and (max-width: 700px){
    .main{
        .landingPage{
            .requestDemo{
                padding: 40px 64px;
                .requestDemoInputRow1{
                    .requestDemoInputEle{
                        .inputBar{
                            width: 323px;
                        }
                    }
                }
            }
            .landingHeader{
                padding-top: 40px;
                padding-left: 24px;
                padding-right: 24px;
                height: 566px;
                display: block;
                img{
                    width: 350px;
                    height: 250px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
@media screen and (max-width: 616px){
    .main{
        .landingPage{
            .landingInfo{
                padding: 100px 24px;
            }
            .requestDemo{
                padding: 40px 24px;
                .requestDemoInputRow1{
                    .requestDemoInputEle{
                        .inputBar{
                            width: 200px;
                        }
                    }
                }
            }
        }
        .landingBundlesEnd{
            padding: 40px 24px;
        }
        .footer{
            padding: 16px 32px;
            .footerLogo{
                .footerLogoWord1{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 200;
                    font-size: 32px;
                    line-height: 80px;
                    color: $pure-black;
                }
                .footerLogoWord2{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 80px;
                    color: $pure-black;
                }
            }
        }
    }
}
@media screen and (max-width: 375px){
    .main{
        .landingPage{
            .landingHeader{
                img{
                    width: 274.65px;
                    height: 238.57px;
                }
            }
            .landingDetails{
                padding: 24px 48px;
            }
            .requestDemo{
                padding: 40px 24px;
                .requestDemoInputRow1{
                    .requestDemoInputEle{
                        .inputBar{
                            width: 200px;
                        }
                    }
                }
            }
            .landingAdvantages{
                padding: 40px 24px;
                display: block;
                .landingEachAdvantage{
                    width: 100%;
                    padding-left: 16px;
                    padding-right: 16px;
                    .landingEachAdvantageHeader{
                        display: flex;
                        margin-bottom: 24px;
                    }
                }
            }
            .landingBundlesEnd{
                .image1{
                    width: 233.5px;
                    height: 543.66px;
                }
                .image2{
                    width: 318px;
                    height: 195px;
                }
            }
        }
        .footer{
            .footerLogo{
                .footerLogoWord1{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 200;
                    font-size: 40px;
                    line-height: 80px;
                    color: $pure-black;
                }
                .footerLogoWord2{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 80px;
                    color: $pure-black;
                }
            }
        }
    }
}
@import "../../genericStyles/index.module.scss";
@import "../../genericStyles/table.module.scss";

.bodyWrapper{
    padding: 50px;
    background: $pure-white;
}
.navPath{
    font-family: $font-inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    text-align: left;
    color: $orient;
    text-transform: uppercase;
}
.sectionWrapper{
    margin-top: 48px;
}
.topHeaderData{
    font-family: $font-inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $organic-black;
  }
.usersPanel{
    .headerRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .headerName{
            font-family: $font-inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
        }
        .filterWrapper{
            margin-left: 8px;
        }
    }
}
.elementWrapper{
    max-width: 100%;
    margin-top: 16px;
    .elementLabel{
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $organic-black;
        display: block;
        margin-left: 16px;
        margin-bottom: 2px;
    }
    .inputEleError{
        &.size_m{
            width: 270px;
            max-width: calc(100% - 10px);
        }
        &.size_l{
            width: 365px;
            max-width: calc(100% - 10px);
        }
        :global{
            .MuiInput-underline{
                border-radius: 24px;
                padding: 4px 16px;
            }
            .MuiOutlinedInput-root{
                border-radius: 24px;
                padding: 0px;
                .MuiOutlinedInput-input{
                    height: 22px;
                    padding: 4px 16px;
                    line-height: 22px;
                }
                .MuiOutlinedInput-notchedOutline{
                    border: 1px solid $error;
                }
            }
        }
    }
    .inputSet{
        margin-left: 16px;
    }
}
.errorText{
    position: absolute;
    color: $error;
    margin-left: 12px;
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
}
.hintMsg{
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: $organic-black;
}
.MuiButton-root:hover{
    background-color: $orient;
}

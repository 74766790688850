@import "../../genericStyles/index.module.scss";
.paymentForm{
    display: flex;
    .productData{
        width: 50%;
        display: inline-block;
        padding-left: 10%;
        padding-top: 2%;
        padding-right: 10%;
        border-right: 1px solid $light-grey1;
        .payment{
            .headerData{
                .videoPaymentHeader{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 24px;
                    color: #798F94;
                }
                .deceasedLastName{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 21px;
                    color: $organic-black;
                }
                .price{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 38px;
                    line-height: 57px;
                    color: $organic-black;
                }
            }
            .deceasedImg{
                margin-top: 24px;
                width: 300px;
                height: 300px;
                img{
                    border-radius: 50%;
                }
            }
        }
        .imagePriceContainer {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
        }
        .toBack{
            display: flex;
            align-items: center;
            .logo{
                margin-right: 8px;
                img{
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 50%;
                }
            }
            .backText{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
                color: $organic-black;
            }
        }
        .creditName{
            display: flex;
            align-items: center;
            .creditNumber{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 33px;
                color: $jewel;
            }
            .creditNumberHint{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 29px;
                color: $jewel;
            }
        }
        .creditHint{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $organic-black;
        }
        .creditPrice{
            font-family: $font-exo;
            font-style: normal;
            font-weight: 400;
            font-size: 38px;
            line-height: 57px;
            color: $organic-black;
        }
        .creditPriceHint{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 21px;
            color: $light-grey2;
            margin-bottom: 64px;
        }
    }
    .checkoutForm{
        width: 50%;
        display: inline-block;
        padding-left: 10%;
        padding-top: 2%;
        padding-right: 10%;
    }
}
@media screen and (max-width: 1024px) {
    .paymentForm{
        display: block;
        .productData{
            display: block;
            width: 100%;
            border-right: none;
            padding-left: 150px;
            padding-right: 150px;
            .payment{
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                .headerData{
                    margin-top: 24px;
                }
                .deceasedImg{
                    height: 250px;
                    img{
                        border-radius: 50%;
                        width: 225px;
                        height: 225px;
                    }
                }
            }
        }
        .checkoutForm{
            padding-left: 150px;
            padding-right: 150px;
            width: 100%;
            display: block;
        }
    }
}
@media screen and (max-width: 768px) {
    .paymentForm{
        .productData{
            display: block;
            width: 100%;
            border-right: none;
            padding-left: 100px;
            padding-right: 100px;
            .payment{
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                .deceasedImg{
                    height: 225px;
                    img{
                        border-radius: 50%;
                        width: 225px;
                        height: 225px;
                    }
                }
            }
        }
        .checkoutForm{
            padding-left: 100px;
            padding-right: 100px;
            width: 100%;
            display: block;
        }
    }
}
@media screen and (max-width:528px) {
    .paymentForm{
        .productData{
            padding-left: 24px;
            padding-right: 24px;
            .payment{
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                .headerData{
                    margin-top: 0px;
                }
                .deceasedImg{
                    height: 120px;
                    width: 150px;
                    img{
                        border-radius: 50%;
                        width: 100px;
                        height: 100px;
                    }
                }
            }
        }
        .checkoutForm{
            padding-left: 24px;
            padding-right: 24px;
            width: 100%;
            display: block;
        }
    }
}
@import "../../genericStyles/index.module.scss";
@import "../../genericStyles/table.module.scss";

.bodyWrapper{
  background: $pure-white;
}
.formWrapper{
    padding: 50px;
    .pageTitlte{
        font-family: $font-inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: $orient;
        text-transform: uppercase;
    }
}
.topHeaderData{
  font-family: $font-inter;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  color: $organic-black;
}
.orgTableHeader{
  display: flex;
  justify-content: space-between;
}
.pageViewIc{
  cursor: pointer;
  color: $orient;
}
@import "../../genericStyles/index.module.scss";
.sectionWrapper{
    padding-left: 10em;
    padding-right: 10em;
}

.formWrapper{
    margin-top: 32px;
    .pageTitlte{
        width: 432px;
        font-family: $font-inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 17px;
        color: $orient;
        .moveTo{
            font-family: $font-inter;
            font-size: 20px;
            font-style: normal;
            line-height: 17px;
            text-align: left;
            color: $orient;
        }
    }
    .formTitlte{
        font-family: $font-exo;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        color: $pure-black;
        margin: 24px 0px;
    }
    .submitBtnWrapper{
        margin-top: 20px;
        text-align: right;
        padding-bottom: 40px;
    }
}
.streamingLists{
    margin-top: 24px;
    .streamingListWrapper{
        .streamEvent{
            border-width: 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px;
            border-style: solid;
            border-bottom-width: 1px;
            border-color: $jungle-mist;
            background-color: $pure-white;
            box-shadow: 0px -1px 0px $greyer, 0px 1px 0px $greyer;
            animation: highlightEle 2s;
            @keyframes highlightEle{
                from {background-color:rgba(0, 127, 0,0.25);}
                99% {background-color:$pure-white;}
                to {background-color:$pure-white;}
            }
            &:first-child{
                border-top-width: 1px;
            }
            .streamingInfo{
                display: flex;
                align-items: center;
                .roomHeader{
                    font-family: $font-bitter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    color: $pure-black;
                }
                .streamDetails{
                    font-family: $font-bitter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: 0px;
                    margin-left: 8px;
                    color: $pure-black;
                }
            }
            .deleteStreamIcon{
                color: $orient;
                cursor: pointer;
            }
        }
    }
}
.errorText{
    position: absolute;
    color: $error;
    width: 260px;
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
}
.modalPopupBody{
    .noMessages{
      text-align: center;
      font-family: $font-exo;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;
      padding: 100px;
      color: $organic-black;
    }
    .imageLoader{
        justify-content: center;
        text-align: center;
        // .spinner{
        //     height: 450px;
        //     width: 450px;
        //     // @media (prefers-reduced-motion: no-preference) {
        //     //     .App-logo {
        //     //       animation: App-logo-spin infinite 20s linear;
        //     //     }
        //     //   }
        //     animation: spin infinite 5s linear;
        //     @keyframes spin {
        //         from {
        //           transform: rotate(0deg);
        //         }
        //         to {
        //           transform: rotate(360deg);
        //         }
        //       }
        // }
    }
}

.elementRows{
    .formElementsRow{
        display:flex;
            flex-wrap: wrap;
            align-items: flex-end;
            .elementWrapper{
                max-width: 100%;
                margin-top: 16px;
                .elementLabel{
                    margin-left: 0;
                    margin-bottom: 6px;
                }
                .elementLabel{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $organic-black;
                    display: block;
                    margin-left: 16px;
                    margin-bottom: 2px;
                }
                .inputPlaces{
                    position: relative;
                    height: 32px;
                    width: 600px;
                    border: 1px solid $pure-black;
                    border-radius: 24px;
                    outline: none;
                    padding: 4px 16px;
                    &:focus{
                        border: 1px solid $jewel;
                        box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
                      }
                    .dropdownContainer{
                        position: absolute;
                        background-color: $pure-white;
                     }
                }
                :global{
                    .MuiInput-underline{
                        border: 1px solid $jewel;
                        border-radius: 24px;
                        padding: 4px 16px;
                        &:before{
                            content: none;
                        }
                        &:after{
                            border-bottom: none;
                        }
                    }
                }
                // .inputEle{
                //     &.size_sm{
                //         width: 175px;
                //         max-width: calc(100% - 10px);
                //     }
                //     &.size_m{
                //         width: 270px;
                //         max-width: calc(100% - 10px);
                //     }
                //     &.size_l{
                //         width: 365px;
                //         max-width: calc(100% - 10px);
                //     }
                //     &.size_lg{
                //         width: 550px;
                //         max-width: calc(100% - 10px);
                //     }
                //     :global{
                //         .MuiInput-underline{
                //             border: 1px solid $pure-black;
                //             border-radius: 24px;
                //             padding: 0px 10px;
                //         }
                //         .MuiOutlinedInput-root{
                //             border-radius: 24px;
                //             padding: 0px;
                //             .MuiOutlinedInput-input{
                //                 height: 22px;
                //                 padding: 5px 10px;
                //                 line-height: 22px;
                //             }
                //             .MuiOutlinedInput-notchedOutline{
                //                 border-color: $organic-black;
                //                 &:focus{
                //                     border-color: $jewel;
                //                     box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
                //                   }
                //             }
                //         }
                //     }
                // }
                .inputEleEdit{
                    &.size_l{
                        width: 365px;
                        max-width: calc(100% - 10px);
                    }
                    :global{
                        .MuiInput-underline{
                            border-radius: 24px;
                            padding: 4px 16px;
                        }
                        .MuiOutlinedInput-root{
                            border-radius: 24px;
                            padding: 0px;
                            background-color: rgba(196, 213, 217, 0.5);
                            .MuiOutlinedInput-input{
                                color: $organic-black;
                                opacity: 0.5;
                                height: 22px;
                                padding: 4px 16px;
                                line-height: 22px;
                            }
                            .MuiOutlinedInput-notchedOutline{
                                border: 1px solid $greyer;
                            }
                        }
                    }
                }
                .inputEleError{
                    &.size_m{
                        width: 270px;
                        max-width: calc(100% - 10px);
                    }
                    &.size_l{
                        width: 365px;
                        max-width: calc(100% - 10px);
                    }
                    :global{
                        .MuiInput-underline{
                            border-radius: 24px;
                            padding: 4px 16px;
                        }
                        .MuiOutlinedInput-root{
                            border-radius: 24px;
                            padding: 0px;
                            .MuiOutlinedInput-input{
                                height: 22px;
                                padding: 4px 16px;
                                line-height: 22px;
                            }
                            .MuiOutlinedInput-notchedOutline{
                                border: 1px solid $error;
                            }
                        }
                    }
                }
            }
            .submitBtnWrapper{
                margin-top: 20px;
                text-align: right;
                padding-bottom: 40px;
                // padding-top: 40px;
            }
    }
}
.checkIcon {
    appearance: none;
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background-color: $pure-white;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid $orient;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
  }
.checkIcon:after{
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    content: url("../../assets/svgs/checkMark.svg");
    color: $pure-white;
    // display: none;
}
// .checkIcon:hover{
//     background-color: red;
// }
.checkIcon:checked{
    background-color: $orient;
}
.expIcon{
    color: $orient;
    cursor: pointer;
}
.disableForm{
    opacity: 0.6;
    pointer-events: none;
}
.homeEvent{
        border-width: 0px;
        display: flex;
        align-items: center;
        padding: 8px;
        border-style: solid;
        border-bottom-width: 1px;
        border-color: $jungle-mist;
        background-color: $pure-white;
        box-shadow: 0px -1px 0px $greyer, 0px 1px 0px $greyer;
        animation: highlightEle 2s;

        @keyframes highlightEle{
            from {background-color:rgba(0, 127, 0,0.25);}
            99% {background-color:$pure-white;}
            to {background-color:$pure-white;}
        }
        &:first-child{
            border-top-width: 1px;
        }
        

        .homeInfo{
            display: flex;
            align-items: center;
            .streamHeader{
                font-family: $font-bitter;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0px;
                color: $pure-black;
            }
            .homeDetails{
                font-family: $font-bitter;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
                letter-spacing: 0px;
                margin-left: 8px;
                color: $pure-black;
            }
        }
        .editHomeIcon{
            margin-left: auto;
            color: $orient;
            cursor: pointer;
        }
        .deleteStreamIcon{
            margin-left: 8px;
            color: $orient;
            cursor: pointer;
        }
}

.fileDropzoneWrapper{
    .fileDropzone{
        width: 550px;
        max-width: 100%;
        .dropzoneContext{
            border: 1px dashed $organic-black;
            padding: 24px 20px;
            background-color: rgba(196, 213, 217, 0.2);
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            border-radius: 8px;
            &.fileUploading{
                .uploadIcon{
                    display: none;
                }
                .progressBarWrapper{
                    display: block;
                }
            }
            .uploadIcon{
                color: $orient;
                margin-right: 8px;
            }
            .feedbackText{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: $pure-black;              
            }
            .progressBarWrapper{
                display: none;
                flex: 1;
                margin-left: 8px;
                margin-right: 16px;
                border-radius: 8px;
                height: 8px;
                background: $jungle-mist;
                .progressIndicator{
                    width: 0%;
                    height: 100%;
                    background-color: $jewel;
                    border-radius: 8px;
                    max-width: 100%;
                }
            }
            .browseBtn{
                margin-left: auto;
            }
            .dropzoneHint{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                color: $pure-black;
            }
        }
       
    }
}

.descriptiveWrapper{
    display: flex;
    align-items: center;
}
.thumbnailWrapper{
    display: flex;
    align-items: center;
    .thumbnailContainer{
        height: 64px;
        width: 64px;
        border-radius: 50%;
        background-color: $pure-white;
        overflow: hidden;
        position: relative;
        background: $greyer;
        .thumbnail{
            transform: translateX(-50%);
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
        }
    }
    .removeImgBtn{
        margin-left: 16px;
    }
}
.checkBoxLabel{
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 16px;
    color: $organic-black;
    padding-left: 4px;
}
.calenderIcon{
    color:$orient
}
.cropImageHint{
    font-family: $font-bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}
.cropImageWrapper{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 8px;
    .cropImageContainer{
        height: 350px;
        background-color: $gray;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .croppedImageView{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        flex: 1;
        .croppedImageWrappper{
            background: $greyer;
            border-radius: 50%;
            width: 160px;
            height: 160px;
            position: relative;
            overflow: hidden;
            .croppedImage{
                transform: translateX(-50%);
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
            }
        }

    }
}

@media screen and (max-width: 1024px){
    .navWrapper {
		padding: 16px 80px;
		.burgerMenu {
			display: block;
		}
		.logoWrapper {
			img {
				height: auto;
				width: 180px;
			}
		}
		.navTitles {
			display: none;
		}
	}
    .sectionWrapper{
        padding: 0 5em;
    }
}
@media screen and (max-width: 768px) {
    .sectionWrapper{
        padding: 0 1em;
    }
    .sectionContainer{
      min-width: calc(100% - 20px);
      max-width: calc(100% - 20px);
    }
    .navWrapper{
      padding: 16px 60px;
      .burgerMenu{
        display: block;
      }
      .logoWrapper{
        img{
          height: auto;
          width: 180px;
        }
      }
      .navTitles{
        display: none;
      }
    }
}
@media screen and (max-width: 420px) {
    .formElementsRow{
        .elementWrapper{
            width: 100%;
        }
    }
    .elementRows{
        .formElementsRow{
            .elementWrapper{
                .inputPlaces
                {
                    width: 320px;
                }
            }
        }
    }
}

.btnWrapper {
    margin-top: 20px;
}
@import "../../genericStyles/index.module.scss";
.calenderView {
	display: flex;
	height: 100%;
	.calenderDisplay {
		display: flex;
        flex-direction: column;
		flex: 1;
		.rbc-calender{
			.rbc-toolbar{
				background-color: red;
			}
		}
	}
}

.monthView {
	flex: 1;
	display: grid;
	grid-template-columns: repeat(7, 1fr);
}
.eachTime{
  	height: 100vh;
}
.time{
	grid-template-columns: repeat(7, 1fr);
	grid-template-rows: repeat(24, 1fr);
}
.weekView {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	height: 100vh;
	  .eachWeekDay{
		border: 1px solid $gray;
		display: flex;
		flex-direction: column;
		max-height: 72px;
		.dayHeader {
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 12px;
			.dateText {
				font-family: Open Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 10px;
				line-height: 12px;
				color: $light-grey2;
			}
			.dateNumText{
				font-family: 'Open Sans';
				font-style: normal;
				font-weight: 400;
				font-size: 22px;
				line-height: 32px;
				color: $organic-black;
			}
			.dateValue {
				font-family: Open Sans;
				font-style: normal;
				font-weight: normal;
				font-size: 22px;
				line-height: 32px;
				color: $organic-black;
			}
		}
	}
}
.eachday {
	border: 1px solid $gray;
	display: flex;
	flex-direction: column;
	min-height: 120px;
	.dayHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 12px;
		.dateText {
			font-family: Open Sans;
			font-style: normal;
			font-weight: bold;
			font-size: 10px;
			line-height: 12px;
			color: $light-grey2;
		}
		.dateValue {
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 22px;
			line-height: 32px;
			color: $organic-black;
		}
	}
	.eventData{
		text-align: center;
		// .eventDataText{
			
		// }
		.event{
			padding-top: 8px;
			padding-bottom: 8px;
			margin-bottom: 4px;
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 11px;
			line-height: 15px;
			color: $organic-black;
			cursor: pointer;
			.eventDetails{
				font-family: Open Sans;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 15px;
				align-items: center;
				color: $organic-black;
			}
		}
	}
}
.dropdownMenu{
    position: absolute;
    top: 133.5px;
    background-color: $pure-white;
    border-radius: 8px;
    padding: 8px 16px;
    z-index: 10;
    border: 1px solid $organic-black;
    &:hover{
      border: 1px solid $jewel;
      box-shadow: 0px 2px 8px rgba(0, 127, 0, 0.4); 
    }
	.searchDetails{
		font-family: $font-bitter;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 24px;
		color: $pure-black;
		word-break: break-word;
		padding-bottom: 8px;
	}
    // span{
    //   white-space: nowrap;
    //   display: block;
    //   cursor: pointer;
    //   color: $orient;
    //   font-family: $font-bitter;
    //   font-style: normal;
    //   font-weight: 300;
    //   font-size: 16px;
    //   line-height: 24px;
    //   text-decoration: underline;
    //   &:hover{
    //     color: $organic-black;
    //   }
    //   &:active{
    //     color: $jewel;
    //   }
    // }
  }
.CalenderHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	.showYear {
		display: flex;
		.actionBtn {
			justify-content: center;
			align-items: center;
			padding: 6px 16px;
			background-color: $jungle-mist;
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			display: flex;
			height: 32px;
			color: $organic-black;
            border: none;
		}
        .actionBtnLeft {
			justify-content: center;
			align-items: center;
			padding: 4px;
			background-color: $jungle-mist;
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			display: flex;
			color: $organic-black;
            border: none;
			height: 32px;
            border-radius: 4px 0px 0px 4px;
            margin-right: 1px;
			cursor: pointer;
		}
        .actionBtnRight {
			justify-content: center;
			height: 32px;
			align-items: center;
			padding: 4px;
			background-color: $jungle-mist;
			font-family: Open Sans;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			display: flex;
			color: $organic-black;
            border: none;
            border-radius: 0px 4px 4px 0px;
            margin-left: 1px;
			cursor: pointer;
		}
	}
	.searchCriteria {
		display: flex;
		align-items: center;
		.filterWrapper {
            margin-right: 16px;
			border: 1px solid $organic-black;
			border-radius: 60px;
			height: 32px;
			overflow: hidden;
			padding-right: 8px;
			& > input {
				&.filterInput {
					width: 250px;
					border: none;
					border-radius: 0;
				}
			}
			:global {
				.MuiOutlinedInput-root {
					background-color: $pure-white;
					height: 32px;
					.PrivateNotchedOutline-root-1 {
						border-width: 0px;
					}
				}
			}
		}
		.siteBar{
			display: flex;
			align-items: center;
			margin-right: 16px;
		}
	}
}
.searchRes{
	&:hover {
		color: "red";
	}
}
.sideCalender{
    padding: 1rem;
    width: 20rem;
    background-color: $organic-black;
    .sideCalenderData{
        margin-top: 2.25rem;
        .sideCalenderHeader{
            display: flex;
            justify-content: space-between;
            .monthYear{
                display: flex;
                .sideCalenderYear{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 39px;
                    color: $pure-white;
                }
                .sideCalenderValue{
                    font-family: Exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 26px;
                    line-height: 39px;
                    color: $granny-smith;
                    margin-left: 16px
                }
            }
            .monthBtn{
                border: none;
                background-color: $organic-black;
                color: $pure-white;
                margin-top: 6px;
				cursor: pointer;
            }
        }

        .sideCalenderMonth{
            display: grid;
            grid-gap: 10px;
            grid-template-columns: repeat(7, 1fr);
            .sideCalenderDay{
                font-family: Open Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 16px;
                color: $light-grey1;
                text-align: center;
            }
            .sideCalenderDateSelect{
                border: none;
                background-color: $organic-black;
                color: $pure-white;
                margin-top: 6px;
                text-align: center;
				cursor: pointer;
                .sideCalenderDateSelected{
                    font-family: Open Sans;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 16px;
                    text-align: center;
                    color: $pure-white;
                }
            }
            .sideCalenderCurrDay{
                background-color: $granny-smith;
                border-radius: 50%;
                padding: 4px;
				color: black;
            }
			.sideCalenderSlcDay{
				background-color: $granny-smith;
				opacity: 0.5;
                border-radius: 50%;
                padding: 4px;
				color: black;
			}
        }
    }
}
.modalPopupBody{
	.deceasedData{
		display: flex;
		justify-content: space-between;
		.deceasedInformation{
			display: flex;
			justify-content: space-between;
			.dataDeceased{
				.deceasedName{
					font-family: $font-bitter;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 24px;
					color: $pure-black;
					word-break: break-word;
				}
				.deceasedLifeSpan{
					font-family: $font-bitter;
					font-style: normal;
					font-weight: 300;
					font-size: 18px;
					line-height: 24px;
					color: $pure-black;
					padding-bottom: 32px;
				}
			}
		}
	}
	.deceasedDetailTitle{
		font-family: $font-bitter;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: $pure-black;
	}
	.dayEventHomeDetails{
		display: flex;
		align-items: center;
		font-family: $font-bitter;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 24px;
		color: $organic-black;
	}
	.deceasedDetailvalue{
		font-family: $font-bitter;
		font-style: normal;
		font-weight: 300;
		font-size: 18px;
		line-height: 24px;
		color: $pure-black;
	}
	.deceasedInfoDetail{
		display: flex;
		align-items: center;
		.deceasedDetailTitle{
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			color: $pure-black;
		}
		.deceasedDetailvalue{
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 300;
			font-size: 18px;
			line-height: 24px;
			color: $pure-black;
			margin-right: 90px;
		}
	}
}

.eventsList{
	padding-top: 24px;
	overflow-y: scroll;
	.dayEventHeaderToday{
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: $granny-smith;
		padding-bottom: 8px;
	}
	.dayEventHeader{
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: $pure-white;
		padding-bottom: 8px;
	}
	.dayEvent{
		.dayEventHomeDetails{
			display: flex;
			align-items: center;
			font-family: $font-bitter;
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			color: $pure-white;
			text-transform: capitalize;
		}
		.dayEventTime{
			font-family: Open Sans;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: $jungle-mist;
			padding-bottom: 4px;
			text-transform: capitalize;
		}
		.dayEventDetails{
			font-family: Open Sans;
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;
			color: $pure-white;
			text-transform: capitalize;
			padding-bottom: 16px;
		}
	}
}

.noEvents{
	text-align: center;
	display: flex;
    align-items: center; 
    justify-content: center;
	font-family: Open Sans;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	color: $pure-white;
	text-transform: capitalize;
	padding-top: 32px;
}
.backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 100;
	top: 0;
	right: 0;
}
.sideDrawerOpen {
	position: fixed;
	height: 100%;
	background: $pure-white;
	top: 0;
	right: 0;
	width: 22.5%;
	z-index: 200;
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	transform: translateX(100%);
	transition: transform 0.5s ease-out;
	transition-duration: 2s;
	overflow: auto;
	box-shadow: -4px 5px 7px 1px rgba(0, 102, 126, 0.15)
  }
  .sideDrawerOpen {
	transform: translateX(0);
  }

@import "../../genericStyles/index.module.scss";
.mainBody{
    margin-left: 170px;
    margin-right: 170px;
    .pageHeader{
        font-family: $font-inter;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: $orient;
    }
    .billing{
        .wallet{
            margin-bottom: 40px;
            .walletHeader{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 33px;
                color: $organic-black;
                padding-bottom: 4px;
            }
            .cards{
                .availableCreditCards{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid $greyer;
                    padding: 8px;
                    .eachCard{
                        display: flex;
                        align-items: center;
                      }
                      .editDelete{
                        display: flex;
                        align-items: center;
                        .last4{
                          font-family: $font-bitter;
                          font-style: normal;
                          font-weight: 300;
                          font-size: 14px;
                          line-height: 21px;
                          color: $organic-black;
                        }
                      }
                }
            }
            .noCards{
                .addCardsHint{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 33px;
                    color: $organic-black;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 16px;
                }
            }
        }
        .transactions{
            .transactionsHeader{
                font-family: $font-exo;
                font-style: normal;
                font-weight: 500;
                font-size: 22px;
                line-height: 33px;
                color: $organic-black;
                padding-bottom: 4px;
            }
            .transaction{
                .availableTransactions{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid $greyer;
                    padding: 8px;
                    .transactionName{
                        display: flex;
                        align-items: center;
                        .availableTransactionsHeader1{
                            font-family: $font-bitter;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 24px;
                            color: $error;
                            margin-right: 44px;
                            width: 100px;
                        }
                        .availableTransactionsHeader2{
                            font-family: $font-bitter;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 24px;
                            color: $jewel;
                            margin-right: 44px;
                            width: 100px;
                        }
                        .availableTransactionsHeader3{
                            font-family: $font-bitter;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 24px;
                            color: $organic-black;
                            margin-right: 44px;
                            width: 100px;
                        }
                        .availableTransactionsData{
                            .availableTransactionAmount{
                                font-family: $font-exo;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 29px;
                                color: $jewel;
                            }
                            .eventLink{
                                font-family: $font-bitter;
                                font-style: normal;
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 24px;
                                text-decoration-line: underline;
                                color: $orient;
                                margin-left: 16px;
                            }
                            .paidBy{
                                font-family: $font-bitter;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 21px;
                                color: $organic-black;
                            }
                            .paidByName{
                                font-family: $font-bitter;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: $organic-black;
                                margin-left: 8px;
                            }
                        }
                    }
                    .transactionInfo{
                        width: 150px;
                        .transactionInfoAmount{
                            display: flex;
                            .transactionInfoHeader{
                                font-family: $font-bitter;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 21px;
                                color: $organic-black; 
                                margin-right: 8px;
                            }
                            .transactionInfoValue{
                                font-family: $font-bitter;
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 21px;
                                color: $organic-black;
                            }
                        }
                    }
                }
            }
            .noTransactions{
                .noTransactionsHint{
                    font-family: $font-exo;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 33px;
                    color: $organic-black;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 16px;
                }
            }
        }
        .loadMore{
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: $organic-black;
            padding: 40px 0px;
            cursor: pointer;
        }
    }
}
@import "../../genericStyles/index.module.scss";

.topHeader{
    background-repeat: no-repeat;
    background-image: linear-gradient(135deg, #001100 50%, #106038 65%, #5CAA9A 100%);
    // background-image: url(../assets/svgs/create-event-header.svg);
    background-size: 100% auto;
    background-position: 50% 50%;
    padding: 32px 0;
    // margin-top: 40px;
    .pageTitle{
        font-family: $font-exo;
        font-style: normal;
        font-weight: normal;
        font-size: 38px;
        line-height: 57px;
        color: $pure-white;
    }
}
.sectionContianer{
    padding: 0 160px;
}
.formWrapper{
    margin-top: 32px;
    .formTitlte{
        font-family: $font-exo;
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 48px;
        color: $pure-black;
        margin-bottom: 24px;
    }
    .elementRows{
        .formElementsRow{
            display:flex;
            flex-wrap: wrap;
            align-items: flex-end;
            .elementWrapper{
                max-width: 100%;
                margin-top: 16px;
                .creditHint{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 21px;
                    color: $organic-black;
                    text-align: right;
                    .creditCount{
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 21px;
                        color: $organic-black;
                    }
                }
                .zeroCredits{
                    text-align: right;
                    margin-top: 4px;
                    .zeroCreditsError{
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 21px;
                        color: $error;
                        .purchaseCredits{
                            font-family: $font-bitter;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 21px;
                            color: $orient; 
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
                .addFuneral{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .cancelAddingFuneral{
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 16px;
                        color: $organic-black;
                    }
                    .saveAndAddAnotherFuneral{
                        .saveAndAddAnotherFuneralLink{
                            font-family: $font-bitter;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 16px;
                            text-align: center;
                            color: $orient;
                            margin-right: 24px;
                        }
                    }
                }
                &.radioWrapper{
                    margin-bottom: auto;
                    margin-right: 10px;
                    .elementLabel{
                        margin-left: 0;
                        margin-bottom: 6px;
                    }
                }
                .elementLabel{
                    font-family: $font-bitter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: $organic-black;
                    display: block;
                    margin-left: 16px;
                    margin-bottom: 2px;
                }
                :global{
                    .MuiInput-underline{
                        border: 1px solid $pure-black;
                        border-radius: 24px;
                        padding: 0px 10px;
                        &:before{
                            content: none;
                        }
                        &:after{
                            border-bottom: none;
                        }
                    }
                }
                .inputEleError{
                    &.size_sm{
                        width: 175px;
                        max-width: calc(100% - 10px);
                    }
                    &.size_m{
                        width: 270px;
                        max-width: calc(100% - 10px);
                    }
                    &.size_l{
                        width: 365px;
                        max-width: calc(100% - 10px);
                    }
                    &.size_lg{
                        width: 550px;
                        max-width: calc(100% - 10px);
                    }
                    :global{
                        .MuiInput-underline{
                            border-radius: 24px;
                            padding: 0px 10px;
                        }
                        .MuiOutlinedInput-root{
                            border-radius: 24px;
                            padding: 0px;
                            .MuiOutlinedInput-input{
                                height: 24px;
                                padding: 4px 16px;
                                line-height: 24px;
                            }
                            .MuiOutlinedInput-notchedOutline{
                                border: 1px solid $error;
                            }
                        }
                    }
                }
                .cSwitch{
                    display: flex;
                    align-items: center;
                    .cSwitchLabel{
                        font-family: $font-bitter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 24px;
                        letter-spacing: 0px;
                    }
                }
                // .inputHint{
                //     margin-left: 16px;
                //     font-family: $font-bitter;
                //     font-size: 14px;
                //     font-style: normal;
                //     font-weight: 300;
                //     line-height: 21px;
                //     letter-spacing: 0px;
                // }
            }
        }
    }
    .eventErr{
        text-align: right;
        font-family: $font-bitter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: $error;
    }
    .submitBtnWrapper{
        margin-top: 20px;
        text-align: right;
        padding-bottom: 40px;
    }
}
.errorText{
    position: absolute;
    color: $error;
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    width: 185px;
}
.fileDropzoneWrapper{
    .fileDropzone{
        width: 550px;
        max-width: 100%;
        .dropzoneContext{
            border: 1px dashed $organic-black;
            padding: 24px 20px;
            background-color: rgba(196, 213, 217, 0.2);
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            border-radius: 8px;
            &.fileUploading{
                .uploadIcon{
                    display: none;
                }
                .progressBarWrapper{
                    display: block;
                }
            }
            .uploadIcon{
                color: $orient;
                margin-right: 8px;
            }
            .feedbackText{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: $pure-black;              
            }
            .progressBarWrapper{
                display: none;
                flex: 1;
                margin-left: 8px;
                margin-right: 16px;
                border-radius: 8px;
                height: 8px;
                background: $jungle-mist;
                .progressIndicator{
                    width: 0%;
                    height: 100%;
                    background-color: $jewel;
                    border-radius: 8px;
                    max-width: 100%;
                }
            }
            .browseBtn{
                margin-left: auto;
            }
            .dropzoneHint{
                font-family: $font-bitter;
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                color: $pure-black;
            }
        }
       
    }
}
.descriptiveWrapper{
    display: flex;
    align-items: center;
}

.thumbnailWrapper{
    display: flex;
    align-items: center;
    .thumbnailContainer{
        height: 64px;
        width: 64px;
        border-radius: 50%;
        background-color: $pure-white;
        overflow: hidden;
        position: relative;
        background: $greyer;
        .thumbnail{
            transform: translateX(-50%);
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
        }
    }
    .removeImgBtn{
        margin-left: 16px;
    }
}
.streamingLists{
    margin-top: 32px;
    .sectionContianer{
        .formTitlte{
            font-family: $font-exo;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 48px;
            color: $pure-black;
            margin-bottom: 24px;
        }
    }
    .streamingListWrapper{
        margin-bottom: 16px;
        .streamEvent{
            border-width: 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px;
            border-style: solid;
            border-bottom-width: 1px;
            border-color: $jungle-mist;
            background-color: $pure-white;
            box-shadow: 0px -1px 0px $greyer, 0px 1px 0px $greyer;
            animation: highlightEle 2s;
            @keyframes highlightEle{
                from {background-color:rgba(0, 127, 0,0.25);}
                99% {background-color:$pure-white;}
                to {background-color:$pure-white;}
            }
            &:first-child{
                border-top-width: 1px;
            }
            .streamingInfo{
                .streamHeader{
                    font-family: $font-bitter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0px;
                    color: $pure-black;
                    .oneCredit{
                        font-family: $font-bitter;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 21px;
                        color: $light-grey2;
                    }
                }
                .streamDetails{
                    font-family: $font-bitter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;
                    letter-spacing: 0px;
                    color: $pure-black;
                }
            }
            .deleteStreamIcon{
                color: $orient;
                cursor: pointer;
            }
        }
    }
}
.timeIcon{
    color:$orient
}
.calenderIcon{
    color:$orient
}
.cropImageHint{
    font-family: $font-bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}
.cropImageWrapper{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 8px;
    .cropImageContainer{
        height: 350px;
        background-color: $gray;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .croppedImageView{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        flex: 1;
        .croppedImageWrappper{
            background: $greyer;
            border-radius: 50%;
            width: 160px;
            height: 160px;
            position: relative;
            overflow: hidden;
            .croppedImage{
                transform: translateX(-50%);
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
            }
        }

    }
}
@media screen and (max-width: 1024px) {
    .sectionContianer{
        padding: 0 80px;
    }
}
@media screen and (max-width: 768px) {
    .sectionContianer{
        padding: 0 10px;
    }
}
@media screen and (max-width: 420px) {
    .sectionContianer{
        .formElementsRow{
            .elementWrapper{
                width: 100%;
            }
        }
    }
}
.headerCell {
  background-color: #C4D5D9 !important;
  text-transform: uppercase !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #00667E !important;
}
  
  .pagination {
    padding: 50px;
    padding-top: 0;
    background-color: #C4D5D9;
    color: #2A7E92;
  }
  
  .pageMover {
    float: right;
    padding-top: 15px;
  }
  
  .pageDropdown {
    float: left;
    padding-top: 15px;
  }
  
  .table {
    margin-bottom: 0 !important;
    padding-top: 40px;
  }
  
  div.filterWrapper{
    border: 1px solid black;
    border-radius: 60px;
    height: 31px;
    overflow: hidden;
    padding-right: 8px;
    &>input{
        &.filterInput{
            width: 500px;
            border: none;
            border-radius: 0;
        }
    }
    .selectFilter{
        border-radius: 0;
        border-left: 1px solid black;
    }
    :global{
        .MuiOutlinedInput-root{
            background-color: white;
            height: 32px;
            .PrivateNotchedOutline-root-1{
                border-width: 0px;
            }
        }
    }
}
@media screen and (max-width: 540px) {
  div.filterWrapper{
    &>input{
        &.filterInput{
            width: 180px;
        }
    }
  }
}
@media screen and (max-width: 420px) {
  div.filterWrapper{
    &>input{
        &.filterInput{
            width: 180px;
        }
    }
  }
}
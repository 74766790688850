@import "../../genericStyles/index.module.scss";

.navWrapper{
    padding: 16px 10em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-top: 40px;
    position: sticky;
    top: 0;
    box-shadow: 2px 2px 8px 2px rgba(0, 26, 32, 0.2);
    z-index: 1000;
    .logoWrapper{
      img{
        cursor: pointer;
        height: 48px;
      }
    }
    .burgerMenu{ 
      display: none;
      &:hover{
        .dropdownMenu{
          display: block;
        }
      }
      .dropdownMenu{
        position:absolute;
        top: 73px;
        right: 0px;
        background-color: white;
        border-radius: 3px;
        border-top-right-radius: 0px;
        padding: 16px 24px;
        a{
          &+a{
            margin-top: 24px;
          }
          white-space: nowrap;
          display: block;
          
          color: $orient;
          font-family: $font-bitter;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          text-decoration: underline;
          &:hover{
            color: $organic-black;
            text-decoration: underline;
          }
          &:active{
            color: $jewel;
            text-decoration: underline;
          }
        }
      }
      &>*{
        font-size: 36px;
        color: $pure-black;
      }
    }
    .navTitles{
        display: block;
        select {
          border: none;
          box-shadow: none;
          background-color: white;
          color: rgb(0, 102, 126);
          max-width: 220px;
          font-family: Bitter, serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          padding: 8px 16px;
          margin-left: 32px;
          option {
            font-family: Bitter, serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            padding: 8px 16px;  
            border: none;
          }
        }
        select:focus option:checked {
          border: none
        }
        select:checked{
          border: none;
        }
        .select-wrap {
          position: relative;
        }
        select:focus {
          border-radius: 0px;
          outline: none;
        }
        .select-wrap:focus-within select {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10
        }
        a{
            padding: 0 12px;
            color: $orient;
            font-family: $font-bitter;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-decoration: underline;
            &:hover{
              color: $organic-black;
              text-decoration: underline;
            }
            &:active{
              color: $jewel;
              text-decoration: underline;
            }
            &:last-child{
                padding: 0px;
            }
        }
    }
}
.loginForm{
  margin-top: 120px;
  padding: 10px;
  .sectionContainer{
    display: flex;
    justify-content: center;
    .formWrappper{
      width: 360px;
      max-width: 100%;
    }
  }
}
.userMsg{
  font-family: $font-bitter;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  &.negative{
    color: $error;
  }
  &.positive{
    color: $success;
  }
}
.formWrappper{
  .formHeading{
    font-family: $font-exo;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: $organic-black;
    display: block;
  }
  .subHeading{
    font-family: $font-bitter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0px;
    display: block;
  }
  .formElements{
    margin-top: 24px;
    .inputWrapperRow{
      &+.inputWrapperRow{
        margin-top: 16px;
      }
      display: flex;
      .inputWrapper{
        flex: 1;
        .inputHeader{
          font-family: $font-bitter;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: $organic-black;
          display: block;
          margin-left: 8px;
        }
        .inputElement{
          padding: 4px 16px;
          outline: none;
          width: calc(100% - 10px);
          border-radius: 16px;
          min-height: 32px;
          background-color: white;
          border: 1px solid $organic-black;
        }
        textarea{
          resize: none;
        }
      }
    }
  }
  .submitWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    .hintMsg{
      font-family: $font-bitter;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color:$organic-black;
      margin-right: 8px;
    }
  }
}
.linkText{
  &.noPadding{
    padding: 0px;
  }
  padding: 0 12px;
  color: $orient;
  font-family: Bitter, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  &:hover{
    color: $organic-black;
    text-decoration: underline;
  }
  &:active{
    color: $jewel;
    text-decoration: underline;
  }
}

@media screen and (max-width: 1024px){
  .navWrapper {
		padding: 16px 80px;
		.burgerMenu {
			display: flex;
      select{
        height: 32px;
        font-size: 16px;
        margin-left: 32px;
      }
		}
		.logoWrapper {
			img {
				height: auto;
				width: 180px;
			}
		}
		.navTitles {
			display: none;
		}
	}
}
@media screen and (max-width: 768px) {
  .sectionContainer{
    min-width: calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
  .navWrapper{
    padding: 16px 60px;
    .burgerMenu{
      display: flex;
      select{
        height: 32px;
        font-size: 16px;
        margin-left: 16px;
      }
    }
    .logoWrapper{
      img{
        height: auto;
        width: 180px;
      }
    }
    .navTitles{
      display: none;
    }
  }
}
$organic-black: #001A20;
$orient: #00667E; 
$jungle-mist: #C4D5D9;
$pure-white: #FFFFFF;
$pure-black: #000000;
$jewel: #167434;
$error: #AE0000;
$success: #1a531b;
$greyer: #C4D5D9;
$gray:#E5E5E5;
$light-grey: #FBFBFB;
$light-blue: #2A7E92;
$light-grey2: #667679;
$granny-smith: #96E095;
$light-grey1: #C4C4C4;

$font-bitter: Bitter,serif;
$font-exo: Exo, sans-serif;
$font-inter: Inter, sans-serif;

body{
    background-color: $pure-white;
}
.actionBtn{
    + .actionBtn{
        margin-left: 8px; 
    }
    &.primary{
        background: $jungle-mist;
        color: $organic-black;
    }  
    &.warning{
        background-color: $orient;
        color: $pure-white;
    }
    &.clean{
        background: $pure-white;
        color: $orient;
    }
    &.error{
        background: $error;
        color: $pure-white;
    }
    &.disabled{
        opacity: 0.5;
        pointer-events: none;
    }  
    border:none;
    font-family: $font-bitter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    letter-spacing: 0px;
}
.inputEle{
    position: relative;
    &.size_sm{
        width: 175px;
        max-width: calc(100% - 10px);
    }
    &.size_m{
        width: 270px;
        max-width: calc(100% - 10px);
    }
    &.size_l{
        width: 365px;
        max-width: calc(100% - 10px);
    }
    &.size_lg{
        width: 550px;
        max-width: calc(100% - 10px);
    }
    &.size_lg_plus{
        width: 900px;
        max-width: calc(100% - 10px);
    }
    :global{
        .MuiInput-underline{
            border: 1px solid $pure-black;
            border-radius: 24px;
            padding: 0px 10px;
        }
        .MuiOutlinedInput-root{
            border-radius: 24px;
            padding: 0px;
            &.Mui-error{
                .MuiOutlinedInput-notchedOutline{
                    border-color: $error;
                }
            }
            .MuiOutlinedInput-input{
                height: 24px;
                padding: 4px 16px;
                line-height: 24px;
            }
            &.Mui-focused{
                .MuiOutlinedInput-notchedOutline{
                    border: 1px solid #167434;
                    box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
                } 
            }
            .MuiOutlinedInput-notchedOutline{
                border-color: $organic-black;
            }
        }
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
            &.MuiOutlinedInput-root{
                border-radius: 24px;
                padding: 0px;
                .MuiOutlinedInput-input{
                    height: 24px;
                    padding: 4px 16px;
                    line-height: 24px;
                }
                &.Mui-focused{
                    .MuiOutlinedInput-notchedOutline{
                        border: 1px solid #167434;
                        box-shadow: 0px 0px 6px rgba(0, 127, 0, 0.5);
                    } 
                }
                .MuiOutlinedInput-notchedOutline{
                    border-color: $organic-black;
                }
            }
        }
    }
}
.segment{
    &+.segment{
        margin-top: 16px;
    }
    &.noPadding{
        padding: 0px;
    }
    padding: 16px;
    border: 1px solid $greyer;
    box-shadow: 0px 4px 4px 0px #E1EAEC;
    background-color: $pure-white;
    border-radius: 10px;
}